import { Order } from '.';

export class Orders {
    count: number;
    limit: number;
    page: number;
    totalPages: number;
    offset: number;
    results: [
        Order
    ]
}