import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { catchError, map, tap, retry } from 'rxjs/operators';
import { Observable, of, throwError } from 'rxjs';
import { QueryOptions } from '@app/_models';
import { environment } from '@environments/environment';

const baseUrl = `${environment.apiUrl}`;

@Injectable({
    providedIn: 'root',
})
export class NotificationService{
    
    httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };

    constructor(private httpClient: HttpClient){ }

    sendNotification(body): Observable<any>{
        var url = `${baseUrl}/sendmail`;

   
        console.log('sendNotification(): ' + url);

        return this.httpClient
            .put<any>(url,body)
            .pipe(
                retry(2),
                catchError((error: HttpErrorResponse) => {
                    console.error(error);
                    return throwError(error);
                })
            );
    }    


    getRequestorEmail(id): Observable<any>{
        var url = `${baseUrl}/useremail?id=${id}`;

   
        console.log('getRequestor(): ' + url);

        return this.httpClient
            .get<any>(url)
            .pipe(
                retry(2),
                catchError((error: HttpErrorResponse) => {
                    console.error(error);
                    return throwError(error);
                })
            );
    }    

    getPMs(): Observable<any>{
        var url = `${baseUrl}/allpms`;

   
        console.log('getPMs(): ' + url);

        return this.httpClient
            .get<any>(url)
            .pipe(
                retry(2),
                catchError((error: HttpErrorResponse) => {
                    console.error(error);
                    return throwError(error);
                })
            );
    }    

    getFinals(): Observable<any>{
        var url = `${baseUrl}/allfinals`;

   
        console.log('getFinals(): ' + url);

        return this.httpClient
            .get<any>(url)
            .pipe(
                retry(2),
                catchError((error: HttpErrorResponse) => {
                    console.error(error);
                    return throwError(error);
                })
            );
    }        

}