<div class="dashintro" *ngIf="account">  <!-- Move this to component -->
    <div class="row justify-content-between align-items-center">
        <div class="col-10">
            <h2>Welcome, {{account.user.firstname}}</h2>
        </div>
        <div class="col-1">
            <div class="navg2" *ngIf="isFinal">
                <a class="snavlink" routerLink="/admin/overview" [routerLinkActive]="['nav-active']" #nav1Child1="routerLinkActive">Settings</a>
            </div>
        </div>
        <div class="col-1">
            <div class="navg2">
                    <a class="snavlink" routerLink="/profile/details" [routerLinkActive]="['nav-active']" #nav1Child1="routerLinkActive">Profile</a>
            </div>
        </div>
    </div>
</div>

<div class="row"  style="margin-bottom: 10px;">
    <div class="col-lg-12">
        <div class="card table-card">
            <div class="row">
                <div class="col-lg-6">
                    <h3 class="card-title">{{viewtitle}}</h3>
                </div>
            </div>
            
            <div class="row" style="height: 10px"></div>
            <div class="row">
                <table class="table table-striped table-borderless table-hover">
                    <thead>
                        <tr>
                            <td scope="col">
                                <a class="btn-common btn-hollow"  id="company_employees_sort" (click)="sortLeads('company_employees')">
                                    <span>PO #</span>
                                </a>
                            </td>
                            <td scope="col">
                                <a class="btn-common btn-hollow"  id="company_employees_sort" (click)="sortLeads('company_employees')">
                                    <span>Job Ticket</span>
                                </a>
                            </td>
                            <td scope="col">
                                <a class="btn-common btn-hollow"  id="company_employees_sort" (click)="sortLeads('company_employees')">
                                    <span>Requestor</span>
                                </a>
                            </td>
                            <td scope="col">
                                <a class="btn-common btn-hollow"  id="company_employees_sort" (click)="sortLeads('company_employees')">
                                    <span>Req Date</span>
                                </a>
                            </td>                            
                            <td scope="col">
                                <a class="btn-common btn-hollow"  id="company_employees_sort" (click)="sortLeads('company_employees')">
                                    <span>Vendor</span>
                                </a>
                            </td>
                            <td scope="col">
                                <a class="btn-common btn-hollow"  id="company_employees_sort" (click)="sortLeads('company_employees')">
                                    <span>Status</span>
                                </a>
                            </td>
                            <td scope="col">
                                <a class="btn-common btn-hollow"  id="company_employees_sort" (click)="sortLeads('company_employees')">
                                    <span>Approver 1</span>
                                </a>
                            </td>
                            <td scope="col">
                                <a class="btn-common btn-hollow"  id="company_revenue_sort" (click)="sortLeads('company_revenue')">
                                    <span>Approver 2</span>
                                </a>
                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        <!--<tr *ngFor="let lead of companyLeads.results | leadSort:'page_count'" [class.is-isp]="lead.isisp" routerLink="/lead-breakdown">-->
                        <!--<tr *ngFor="let lead of companyLeads.results" [class.is-isp]="lead.isisp" routerLink="/lead-breakdown"> -->
                        <!--<tr *ngFor="let lead of dataService.companyLeads.results" [class.is-isp]="lead.isisp" routerLink="/leads/lead-breakdown"> -->

                        <tr *ngFor="let order of dataService.orders | paginate: { id: 'pagination1', itemsPerPage: this.itemsperpage, currentPage: currentpage, totalItems: totalrecords }" (click)="editOrder(order)">       
                            <td> {{ order.id }} </td>
                            <td> {{ order.jobs }} </td>
                            <td> {{ order.requestorname}} </td>
                            <td> {{ order.requestdateformatted}} </td>
                            <td> {{ order.vendorname || ' (Not Assigned)'}} </td>
                            <td> {{ order.status }} </td>
                            <td> {{ order.approver1date.substr(0,10)!='0000-00-00' ? order.approver1name + ' (approved: ' +  order.approver1dateformatted + ')' : order.approver1name  || '(Not Assigned)'}} </td>
                            <td> {{ order.approver2date.substr(0,10)!='0000-00-00' ? order.approver2name + ' (approved: ' +  order.approver2dateformatted + ')' : order.approver2name  || '(Not Assigned)'}} </td>

                        </tr>
                    </tbody>
                </table>
                <div class="cardFoot">
                    <pagination-controls id="pagination1"  previousLabel="Prev" nextLabel="Next" responsive="true" (pageChange)="handlePageChange($event)"></pagination-controls>
                </div>
            </div>

        </div>
    </div>
</div>

<!--<div class="row"  style="margin-bottom: 10px;" *ngIf="isPM || isFinal">-->
<div class="row"  style="margin-bottom: 10px;" >
    <div class="col-lg-12">
        <div class="card table-card">
            <div class="row">
                <div class="col-lg-6">
                    <h3 class="card-title">{{viewtitle2}}</h3>
                </div>
            </div>
            
            <div class="row" style="height: 10px"></div>
            <div class="row">
                <table class="table table-striped table-borderless table-hover">
                    <thead>
                        <tr>
                            <td scope="col">
                                <a class="btn-common btn-hollow"  id="company_employees_sort" (click)="sortLeads('company_employees')">
                                    <span>PO #</span>
                                </a>
                            </td>
                            <td scope="col">
                                <a class="btn-common btn-hollow"  id="company_employees_sort" (click)="sortLeads('company_employees')">
                                    <span>Job Ticket</span>
                                </a>
                            </td>
                            <td scope="col">
                                <a class="btn-common btn-hollow"  id="company_employees_sort" (click)="sortLeads('company_employees')">
                                    <span>Requestor</span>
                                </a>
                            </td>
                            <td scope="col">
                                <a class="btn-common btn-hollow"  id="company_employees_sort" (click)="sortLeads('company_employees')">
                                    <span>Req Date</span>
                                </a>
                            </td>                            
                            <td scope="col">
                                <a class="btn-common btn-hollow"  id="company_employees_sort" (click)="sortLeads('company_employees')">
                                    <span>Vendor</span>
                                </a>
                            </td>
                            <td scope="col">
                                <a class="btn-common btn-hollow"  id="company_employees_sort" (click)="sortLeads('company_employees')">
                                    <span>Status</span>
                                </a>
                            </td>
                            <td scope="col">
                                <a class="btn-common btn-hollow"  id="company_employees_sort" (click)="sortLeads('company_employees')">
                                    <span>Approver 1</span>
                                </a>
                            </td>
                            <td scope="col">
                                <a class="btn-common btn-hollow"  id="company_revenue_sort" (click)="sortLeads('company_revenue')">
                                    <span>Approver 2</span>
                                </a>
                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        <!--<tr *ngFor="let lead of companyLeads.results | leadSort:'page_count'" [class.is-isp]="lead.isisp" routerLink="/lead-breakdown">-->
                        <!--<tr *ngFor="let lead of companyLeads.results" [class.is-isp]="lead.isisp" routerLink="/lead-breakdown"> -->
                        <!--<tr *ngFor="let lead of dataService.companyLeads.results" [class.is-isp]="lead.isisp" routerLink="/leads/lead-breakdown"> -->

                        <tr *ngFor="let order of dataService.orders2 | paginate:  { id: 'pagination2', itemsPerPage: this.itemsperpage2, currentPage: currentpage2, totalItems: totalrecords2 }" (click)="editOrder(order)">       
                            <td> {{ order.id }} </td>
                            <td> {{ order.jobs }} </td>
                            <td> {{ order.requestorname}} </td>
                            <td> {{ order.requestdateformatted}} </td>
                            <td> {{ order.vendorname || ' (Not Assigned)'}} </td>
                            <td> {{ order.status }} </td>
                            <td> {{ order.approver1date.substr(0,10)!='0000-00-00' ? order.approver1name + ' (approved: ' +  order.approver1dateformatted + ')' : order.approver1name  || '(Not Assigned)'}} </td>
                            <td> {{ order.approver2date.substr(0,10)!='0000-00-00' ? order.approver2name + ' (approved: ' +  order.approver2dateformatted + ')' : order.approver2name  || '(Not Assigned)'}} </td>

                        </tr>
                    </tbody>
                </table>
                <div class="cardFoot">
                    <pagination-controls id="pagination2" previousLabel="Prev" nextLabel="Next" responsive="true" (pageChange)="handlePageChange2($event)"></pagination-controls>
                </div>
            </div>

        </div>
    </div>
</div>

