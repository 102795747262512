import { Injectable } from '@angular/core';
import { Order } from '@app/_models';
import moment, { Moment } from 'moment';
import { QueryOptions } from '@app/_models/query-opts';
import { takeUntil, map } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';

@Injectable()
export class DataService {

    public orders: any;
    public ordersOrig: any;
    public orders2: any;
    public order: any;
    public orderitems: any;
    public itemslist: any;
    public vendorslist: any;
    public noteslist: any;
    public item: any;
    public selectedVendor: any;
    public selecteditem: any;
    public selecteditemid: any;
    public selecteditemdescription: any;
    public startDate: any;
    public endDate: any;
    public showSimpleDatePicker: boolean;
    public selectedDates: { startDate: Moment, endDate: Moment };
    public dialogRef: any;
    //public shipto: string;
    //public shiptoaddress: string;
    public approver1 = 1;
    public approver2 = 3;
    public isFinal = false;
    public canComplete = false;
    public canAdd = false;


    
    queryOptions: QueryOptions = new QueryOptions();
    
    public constructor( ) {

        //this.shipto = 'FifthColor/Semicolon';
        //this.shiptoaddress = 'Receiving Dock Off County Rd TT\n1500 Stedman Way\nSheboygan Falls, WI 53085';


    }

    setShipTo() {
        this.order.shipto = 'FifthColor/Semicolon';
        this.order.shiptoaddress = 'Receiving Dock Off County Rd TT\n1500 Stedman Way\nSheboygan Falls, WI 53085';
    }

}