<div *ngIf="!adding" >

  <div class="row">
    <div class="col-8">
      <mat-form-field style="width:90%" autocomplete="off">
        <mat-label>Search</mat-label>
        <input matInput [formControl]="search" autocomplete="off" type="search">
      </mat-form-field>
    </div>
    <div class="col-3"><button class="btn btn-primary" (click)="addNote()">Add New Note</button></div>
    <div class="col-1">
      <i class='fas fa-window-close' style='font-size:24px;color:gray; float: right;' (click)="close()"></i>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <mat-dialog-content class="mat-typography">
        <h2 mat-dialog-title>Note for Purchase Order - {{dataService.order.id}}</h2>
        <mat-selection-list #notes [multiple]="false" (selectionChange)="selectionChange($event.option)">
          <div class="row">
            <div class="col-6">Notes</div>
            <div class="col-2">Author</div>
            <div class="col-4">Date</div>

          </div>
          <hr>
          <mat-list-option *ngFor="let note of $search|async" style="height: auto; margin-bottom: 10px;" (click)="itemSelected(note)" [value]="note" [selected]="notesControl.value &&notesControl.value?.indexOf(note)>=0">
            
            <div class="row" style="height: auto">
              <div class="col-6">{{note['note']}}</div>
              <div class="col-2">{{note['authorname']}}</div>
              <div class="col-4">{{note['dateentered'] | date:'short'}}</div>
            </div>
          </mat-list-option>
        </mat-selection-list>
      </mat-dialog-content>
    </div>
  </div>
</div>



<!-- ADDING -->
<div *ngIf="adding">
  <div class="col-8"><h2 mat-dialog-title>Adding New Note</h2></div>

  <hr>

  <mat-form-field style="width:60%" autocomplete="off">
    <mat-label>Note</mat-label>
    <textarea rows=8 matInput autocomplete="off" [(ngModel)]="note"></textarea>
  </mat-form-field>

  <div class="row">
    <div class="col-1">
      <button class="btn btn-primary" (click)="saveNote()">Save</button>
    </div>
    <div class="col-1">
      <button class="btn btn-danger" (click)="cancelNote()">Cancel</button>
    </div>
  </div>
</div>