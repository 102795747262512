import { Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, finalize } from 'rxjs/operators';
import { LoginService, RegisterService } from './';

import { environment } from '@environments/environment';
import { Account } from '@app/_models';
import { CookieService } from 'ngx-cookie-service';
import { DataService } from '@app/_services/data.service';

const baseUrl = `${environment.apiUrl}`;

@Injectable({ providedIn: 'root' })
export class AccountService {
    private accountSubject: BehaviorSubject<Account>;
    public account: Observable<Account>;
    //private test_registerUrl = 'https://reportroverapi.carrollsolutions.com/register';
    //private test_loginUrl = 'https://reportroverapi.carrollsolutions.com/login';

    constructor(
        private router: Router,
        private http: HttpClient,
        private cookieService: CookieService,
        private route: ActivatedRoute,
        private dataService: DataService
        //private loginService: LoginService
        //private registerService: RegisterService
    ) {
        this.accountSubject = new BehaviorSubject<Account>(null);
        this.account = this.accountSubject.asObservable();
    }

    public get accountValue(): Account {
        return this.accountSubject.value;
    }

    login(email: string, password: string) {
        /*following code for fake backend login call*/
        return this.http.post<any>(`${baseUrl}/login`, { email, password })
            .pipe(map(account => {
                this.accountSubject.next(account);

                this.cookieService.delete('po-auth-token'); // return req.headers["rr-auth-cookie"];
                this.cookieService.delete('po-refresh-token'); // return req.headers["rr-auth-cookie"];

                this.cookieService.set('po-auth-token', account.jwtToken, { expires: 2, sameSite: 'Lax' }); // return req.headers["rr-auth-cookie"];
                this.cookieService.set('po-refresh-token', account.jwtRefreshToken, { expires: 365 }); // return req.headers["rr-auth-cookie"];
                this.startRefreshTokenTimer();
                return account;
            }));
        /* Code below is for api login call*/
       // return this.loginService.login({ email, password })
         //    .pipe(map(account => {
           //      this.accountSubject.next(account);
             //    this.cookieService.set('rr-auth-token', account.jwtToken); // return req.headers["rr-auth-cookie"];
               //  this.cookieService.set('rr-refresh-token', account.jwtRefreshToken); // return req.headers["rr-auth-cookie"];
                 //this.startRefreshTokenTimer();
                 //return account;
             //}));
    }

    logout() {
        var refreshtoken = this. cookieService.get('po-refresh-token');
        this.http.post<any>(`${baseUrl}/revoke-token`, {refreshtoken}).subscribe();
        this.stopRefreshTokenTimer();
        this.cookieService.delete('po-auth-token'); // return req.headers["rr-auth-cookie"];
        this.cookieService.delete('po-refresh-token'); // return req.headers["rr-auth-cookie"];
        this.accountSubject.next(null);
        this.router.navigate(['/account/login']);
    }

    refreshToken() {


            var refreshtoken = this. cookieService.get('po-refresh-token');
            debugger;
            return this.http.post<any>(`${baseUrl}/refresh-token`, {refreshtoken} )
                .pipe(map((account) => {

                    if (account) {

                        this.accountSubject.next(account);
                        this.startRefreshTokenTimer();
                        return account;
                    } else {
                        this.stopRefreshTokenTimer();
                        this.accountSubject.next(null);
                        this.router.navigate(['/account/login']);
                    }
                    //this.router.navigate(['/account/login']);
                }));

    }

    register(account: Account) {
        return this.http.post(`${baseUrl}/register`, account);
        /*Following code is for api register call*/

        // return this.registerService.register(account)
        //     .pipe(map(account => {

        //         return account;

        //     }));
    }

    verifyEmail(token: string) {
        return this.http.post(`${baseUrl}/verify-email`, { token });
    }

    forgotPassword(email: string) {
        return this.http.post(`${baseUrl}/forgot-password`, { email });
    }

    validateResetToken(token: string) {
        return this.http.post(`${baseUrl}/validate-reset-token`, { token: token });
    }

    resetPassword(token: string, password: string, confirmPassword: string) {
        return this.http.post(`${baseUrl}/reset-password`, { token, password, confirmPassword });
    }

    getAll() {
        return this.http.get<Account[]>(`${baseUrl}/users`);
    }

    getById(id: string) {
        return this.http.get<Account>(`${baseUrl}/users/${id}`);
    }

    create(params) {
        return this.http.post(`${baseUrl}/users`, params);
    }

    update(id, params) {

        return this.http.put(`${baseUrl}/users/${id}`, params)
            .pipe(map((account: any) => {
                // update the current account if it was updated
                if (account.id === this.accountValue.id) {
                    // publish updated account to subscribers
                    account = { ...this.accountValue, ...account };
                    this.accountSubject.next(account);
                }
                return account;
            }));
    }

    delete(id: string) {
        return this.http.delete(`${baseUrl}/${id}`)
            .pipe(finalize(() => {
                // auto logout if the logged in account was deleted
                if (id === this.accountValue.id)
                    this.logout();
            }));
    }

    // helper methods

    private refreshTokenTimeout;

    private startRefreshTokenTimer() {
        // parse json object from base64 encoded jwt token
        const jwtToken = JSON.parse(atob(this.accountValue.jwtToken.split('.')[1]));
        // set a timeout to refresh the token a minute before it expires
        const expires = new Date(jwtToken.exp * 1000);
        const timeout = expires.getTime() - Date.now() - (60 * 1000);
        this.refreshTokenTimeout = setTimeout(() => { this.refreshToken().subscribe() }, timeout);
    }

    private stopRefreshTokenTimer() {
        clearTimeout(this.refreshTokenTimeout);
    }
}