// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  //apiUrl: 'https://reportroverapi.carrollsolutions.com', //(was localhost:4000)
  //apiUrl: 'https://reportroverapi.carrollsolutions.com',
  //baseDevUrl: 'https://reportroverapi.carrollsolutions.com/',
  apiUrl: 'https://podevapi.fifthcolor.com',
  //apiUrl: 'https://poapi.carrollsolutions.com',
  homepage: 'http://podev.fifthcolor.com',
  version: 'beta 1.04',
  message: 'You are currently in the development environment'
  //baseDevUrl: 'https://api.carrollsolutions.com/',
  //baseLeadUrlPrefix: 'https://api.reportrover.com/tracker/org/'/*,
  //baseLeadUrlSuffix: '/reports/leads'
};

export const GOOGLE_APPLICATION_CREDENTIALS = "../../../credentials.json";

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
