import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { catchError, map, tap, retry } from 'rxjs/operators';
import { Observable, of, throwError } from 'rxjs';
import { environment } from '@environments/environment';

const baseUrl = `${environment.apiUrl}`;

@Injectable({
    providedIn: 'root',
})
export class NotesService{
    
    httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };

    constructor(private httpClient: HttpClient){ }

    getNotes(id): Observable<any>{
        var url = `${baseUrl}/notes?id=${id}`;

   
        console.log('getNotes(): ' + url);

        return this.httpClient
            .get<any>(url)
            .pipe(
                retry(2),
                catchError((error: HttpErrorResponse) => {
                    console.error(error);
                    return throwError(error);
                })
            );
    }

    addNote(note): Observable<any>{
        var url = `${baseUrl}/notes`;

   
        console.log('addNote(): ' + url);

        return this.httpClient
            .put<any>(url, note)
            .pipe(
                retry(2),
                catchError((error: HttpErrorResponse) => {
                    console.error(error);
                    return throwError(error);
                })
            );
    }    


}