<h1>User Accounts</h1>

<a routerLink="add" class="btn btn-sm btn-success mb-2">Create Account</a>
<table class="table table-striped">
    <thead>
        <tr>
            <th style="width:30%">Name</th>
            <th style="width:30%">Email</th>
            <th style="width:30%">Roles</th>
            <th style="width:10%"></th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let account of accounts">
            <td>{{account.firstname}} {{account.lastname}}</td>
            <td>{{account.email}}</td>
            <td>{{account.role}}</td>
            <td style="white-space: nowrap">
                <a routerLink="edit/{{account.id}}" class="btn btn-sm btn-primary mr-1 spacer-r-5">Edit</a>
                <!--<button (click)="deleteAccount(account.id)" class="btn btn-sm btn-danger btn-delete-account" [disabled]="account.isDeleting">
                    <span *ngIf="account.isDeleting" class="spinner-border spinner-border-sm"></span>
                    <span *ngIf="!account.isDeleting">Delete</span>
                </button>-->
            </td>
        </tr>
        <tr *ngIf="!accounts">
            <td colspan="4" class="text-center">
                <span class="spinner-border spinner-border-lg align-center"></span>
            </td>
        </tr>
    </tbody>
</table>