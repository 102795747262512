import { NgModule, APP_INITIALIZER, OnInit } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClientXsrfModule } from '@angular/common/http';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { appInitializer } from './_helpers';
import { JwtInterceptor, ErrorInterceptor } from './_interceptors';
import { AccountService } from './_services';
import { AppComponent } from './app.component';
import { AlertComponent } from './_components';
import { PicklistComponent } from './_components';
import { VendorPicklistComponent } from './_components';
import { NotesComponent } from './_components';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';


import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthInterceptor } from './_interceptors';
import { LoadingInterceptor } from './_interceptors';
import { CommonModule } from '@angular/common';
import { DataService } from './_services/data.service';;
import { MatSliderModule } from '@angular/material/slider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatNativeDateModule } from '@angular/material/core';
import { MatListModule } from '@angular/material/list';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgxPaginationModule } from 'ngx-pagination';

import { DashboardComponent } from './orders/dashboard';
import { CompletedOrdersComponent } from './orders/orders/completedorders.component';
import { AllOrdersComponent } from './orders/orders/allorders.component';

import { OrderComponent } from './orders/order';

import { NgxTypeaheadModule } from 'ngx-typeahead';

import { LocationStrategy, HashLocationStrategy, PathLocationStrategy } from '@angular/common';



// Import from library
import {
    NgxAwesomePopupModule,
    DialogConfigModule,
    ConfirmBoxConfigModule,
    ToastNotificationConfigModule
 } from '@costlydeveloper/ngx-awesome-popup';


@NgModule({

    imports: [
        BrowserModule,
        ReactiveFormsModule,
        FormsModule,
        NgxPaginationModule,
        HttpClientModule,
        HttpClientXsrfModule.withOptions({
            cookieName: 'Report-Rover-Cookie',
            headerName: 'Report-Rover-Header'
        }),
        AppRoutingModule,
        BrowserAnimationsModule,
        CommonModule,
        BrowserModule,
        MatSliderModule,
        MatFormFieldModule,
        MatDialogModule,
        MatListModule,
        MatCheckboxModule,
        MatInputModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatProgressSpinnerModule,
        ReactiveFormsModule,
        NgxTypeaheadModule,
        NgxDaterangepickerMd.forRoot(),
        NgxAwesomePopupModule.forRoot(), // Essential, mandatory main module.
        DialogConfigModule.forRoot(), // Needed for instantiating dynamic components.
        ConfirmBoxConfigModule.forRoot(), // Needed for instantiating confirm boxes.
        ToastNotificationConfigModule.forRoot() // Needed for instantiating toast notifications.

    ],
    declarations: [
        AppComponent,
        AlertComponent,
        DashboardComponent,
        CompletedOrdersComponent,
        AllOrdersComponent,
        OrderComponent,
        PicklistComponent,
        VendorPicklistComponent,
        NotesComponent
    ],

    providers: [
        { provide: APP_INITIALIZER, useFactory: appInitializer, multi: true, deps: [AccountService] },
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        DataService,
        [Location, {provide: LocationStrategy, useClass: HashLocationStrategy}],

        /* provider used to create fake backend */
        //fakeBackendProvider
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }