import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { catchError, map, tap, retry } from 'rxjs/operators';
import { Observable, of, throwError } from 'rxjs';
import { Orders } from '@app/_models';
import { QueryOptions } from '@app/_models';
import { DateRangeService } from './daterange.service';
import { environment } from '@environments/environment';

const baseUrl = `${environment.apiUrl}`;

@Injectable({
    providedIn: 'root',
})
export class OrdersService{
    
    httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };

    constructor(private httpClient: HttpClient, private dateRangeService: DateRangeService){ }

    getOrders(queryOpts: QueryOptions): Observable<Orders>{
        var url = `${baseUrl}/purchaseorders`;

        //this.url = (this.url + 'limit=' + queryOpts.pageSize + '&' + 'page=' + queryOpts.page + '&sortorder=' + queryOpts.sortOrder + '&sortdirection=' + queryOpts.sortDirection);
    
        //this.url = (this.url + 'page=' + queryOpts.page + '&sortorder=' + queryOpts.sortOrder + '&sortdirection=' + queryOpts.sortDirection);        

        /* if we have a start and end date then append to url/
        if(typeof queryOpts.start !== 'undefined'){
            if(typeof queryOpts.end === 'undefined'){
                //require an end date
                return;
            }
            else{
                this.url = 
                (
                    this.url + 
                        '&startdate=' + 
                    this.dateRangeService.parseDate(queryOpts.start) + 
                        '&enddate=' + 
                    this.dateRangeService.parseDate(queryOpts.end)
                );
            }
        }
*/
        console.log('getOrders(): ' + url);

        return this.httpClient
            .get<Orders>(url)
            .pipe(
                retry(2),
                catchError((error: HttpErrorResponse) => {
                    console.error(error);
                    return throwError(error);
                })
            );
    }

    getMyOrders(queryOpts: QueryOptions, userid): Observable<Orders>{
        var url = `${baseUrl}/purchaseorders?requestor=${userid}`;

        //this.url = (this.url + 'limit=' + queryOpts.pageSize + '&' + 'page=' + queryOpts.page + '&sortorder=' + queryOpts.sortOrder + '&sortdirection=' + queryOpts.sortDirection);
    
        //this.url = (this.url + 'page=' + queryOpts.page + '&sortorder=' + queryOpts.sortOrder + '&sortdirection=' + queryOpts.sortDirection);        

        /* if we have a start and end date then append to url/
        if(typeof queryOpts.start !== 'undefined'){
            if(typeof queryOpts.end === 'undefined'){
                //require an end date
                return;
            }
            else{
                this.url = 
                (
                    this.url + 
                        '&startdate=' + 
                    this.dateRangeService.parseDate(queryOpts.start) + 
                        '&enddate=' + 
                    this.dateRangeService.parseDate(queryOpts.end)
                );
            }
        }
*/
        console.log('getMyOrders(): ' + url);

        return this.httpClient
            .get<Orders>(url)
            .pipe(
                retry(2),
                catchError((error: HttpErrorResponse) => {
                    console.error(error);
                    return throwError(error);
                })
            );
    }

    getMyActiveOrders(queryOpts: QueryOptions, userid): Observable<Orders>{
        var url = `${baseUrl}/purchaseorders?requestor=${userid}&status=~denied`;



        console.log('getMyActiveOrders(): ' + url);

        return this.httpClient
            .get<Orders>(url)
            .pipe(
                retry(2),
                catchError((error: HttpErrorResponse) => {
                    console.error(error);
                    return throwError(error);
                })
            );
    }

    
    getMyApprovedOrders(queryOpts: QueryOptions, userid): Observable<Orders>{
        var url = `${baseUrl}/purchaseorders?requestor=${userid}&status=approved`;


        console.log('getMyApprovedOrders(): ' + url);

        return this.httpClient
            .get<Orders>(url)
            .pipe(
                retry(2),
                catchError((error: HttpErrorResponse) => {
                    console.error(error);
                    return throwError(error);
                })
            );
    }

    getMyOrdersForApproval(queryOpts: QueryOptions): Observable<Orders>{
        var url = `${baseUrl}/purchaseorders?status=new`;

        console.log('getOrdersForApproval(): ' + url);

        return this.httpClient
            .get<Orders>(url)
            .pipe(
                retry(2),
                catchError((error: HttpErrorResponse) => {
                    console.error(error);
                    return throwError(error);
                })
            );
    }

    getMyOrdersForFinalApproval(queryOpts: QueryOptions): Observable<Orders>{
        var url = `${baseUrl}/purchaseorders?&status=initial approval`;

        console.log('getMyOrdersForFinalApproval(): ' + url);

        return this.httpClient
            .get<Orders>(url)
            .pipe(
                retry(2),
                catchError((error: HttpErrorResponse) => {
                    console.error(error);
                    return throwError(error);
                })
            );
    }

    getAllPurchaseOrders(queryOpts: QueryOptions): Observable<Orders>{
        var url = `${baseUrl}/purchaseorders`;

        console.log('getgetAllPurchaseOrders(): ' + url);

        return this.httpClient
            .get<Orders>(url)
            .pipe(
                retry(2),
                catchError((error: HttpErrorResponse) => {
                    console.error(error);
                    return throwError(error);
                })
            );
    }    

    getAllNotDraftPurchaseOrders(queryOpts: QueryOptions): Observable<Orders>{
        var url = `${baseUrl}/purchaseorders?status=~draft`;

        console.log('getgetAllPurchaseOrders(): ' + url);

        return this.httpClient
            .get<Orders>(url)
            .pipe(
                retry(2),
                catchError((error: HttpErrorResponse) => {
                    console.error(error);
                    return throwError(error);
                })
            );
    }    

    getCompletedPurchaseOrders(queryOpts: QueryOptions): Observable<Orders>{
        var url = `${baseUrl}/purchaseorders?status=completed`;

        console.log('getCompleted(): ' + url);

        return this.httpClient
            .get<Orders>(url)
            .pipe(
                retry(2),
                catchError((error: HttpErrorResponse) => {
                    console.error(error);
                    return throwError(error);
                })
            );
    }    


    getApprovedPurchaseOrders(queryOpts: QueryOptions): Observable<Orders>{
        var url = `${baseUrl}/purchaseorders?status=approved`;

        console.log('getApprovedorders(): ' + url);

        return this.httpClient
            .get<Orders>(url)
            .pipe(
                retry(2),
                catchError((error: HttpErrorResponse) => {
                    console.error(error);
                    return throwError(error);
                })
            );
    }    


    getMyPreviousApprovals(queryOpts: QueryOptions, userid): Observable<Orders>{
        var url = `${baseUrl}/purchaseorders?pm=${userid}&status=~new`;

        console.log('getMyPreviousApprovals(): ' + url);

        return this.httpClient
            .get<Orders>(url)
            .pipe(
                retry(2),
                catchError((error: HttpErrorResponse) => {
                    console.error(error);
                    return throwError(error);
                })
            );
    }    

    getMyPreviousFinalApprovals(queryOpts: QueryOptions, userid): Observable<Orders>{
        var url = `${baseUrl}/purchaseorders?final=${userid}&status=approved`;

        console.log('getMyPreviousFinalApprovals(): ' + url);

        return this.httpClient
            .get<Orders>(url)
            .pipe(
                retry(2),
                catchError((error: HttpErrorResponse) => {
                    console.error(error);
                    return throwError(error);
                })
            );
    }    

    getOrder(ponbr): Observable<any>{
        var url = `${baseUrl}/purchaseorder?ponbr=${ponbr}`;

   
        console.log('getOrder(): ' + url);

        return this.httpClient
            .get<any>(url)
            .pipe(
                retry(2),
                catchError((error: HttpErrorResponse) => {
                    console.error(error);
                    return throwError(error);
                })
            );
    }

    addOrder(order): Observable<any>{
        var url = `${baseUrl}/purchaseorder`;

   
        console.log('addOrder(): ' + url);

        return this.httpClient
            .put<any>(url, order)
            .pipe(
                retry(2),
                catchError((error: HttpErrorResponse) => {
                    console.error(error);
                    return throwError(error);
                })
            );
    }

    updateOrder(ponbr, updates): Observable<any>{
        var url = `${baseUrl}/purchaseorder?ponbr=${ponbr}`;

   
        console.log('updateOrder(): ' + url);

        return this.httpClient
            .post<any>(url, updates)
            .pipe(
                retry(2),
                catchError((error: HttpErrorResponse) => {
                    console.error(error);
                    return throwError(error);
                })
            );
    }

    updateItem(item): Observable<any>{
        var url = `${baseUrl}/purchaseorderitems?id=${item.id}`;

        var newitem = item;
        delete newitem.source;
        delete newitem.item;
        delete newitem.description;
        console.log('updateOrderItem(): ' + url);

        return this.httpClient
            .post<any>(url, newitem)
            .pipe(
                retry(2),
                catchError((error: HttpErrorResponse) => {
                    console.error(error);
                    return throwError(error);
                })
            );
    }    

    removeItem(item): Observable<any>{
        var url = `${baseUrl}/purchaseorderitems?id=${item.id}`;

        console.log('removeOrderItem(): ' + url);

        return this.httpClient
            .delete<any>(url)
            .pipe(
                retry(2),
                catchError((error: HttpErrorResponse) => {
                    console.error(error);
                    return throwError(error);
                })
            );
    }        

    deleteOrder(id): Observable<any>{
        var url = `${baseUrl}/purchaseorder?id=${id}`;

        console.log('removeOrder(): ' + url);

        return this.httpClient
            .delete<any>(url)
            .pipe(
                retry(2),
                catchError((error: HttpErrorResponse) => {
                    console.error(error);
                    return throwError(error);
                })
            );
    }        

    addItem(item, poid): Observable<any>{

        console.log("SHOULD NEVER BE CALLED");
        var url = `${baseUrl}/purchaseorderitems?poid=${poid}`;
        debugger;
//        var newitem = item;
  //      newitem.item_id = newitem.id;
    //    delete newitem.id;
      //  delete newitem.source;
        //delete newitem.description;
        //delete newitem.notes;
        console.log('addOrderItem(): ' + url);

        return this.httpClient
            .put<any>(url, item)
            .pipe(
                retry(2),
                catchError((error: HttpErrorResponse) => {
                    console.error(error);
                    return throwError(error);
                })
            );
    }        

    updateArrived(id): Observable<any>{
        var url = `${baseUrl}/purchaseorderitemsarrived?id=${id}`;

        return this.httpClient
            .post<any>(url, null)
            .pipe(
                retry(2),
                catchError((error: HttpErrorResponse) => {
                    console.error(error);
                    return throwError(error);
                })
            );
    }       

    updateNotArrived(id): Observable<any>{
        var url = `${baseUrl}/purchaseorderitemsnotarrived?id=${id}`;

        return this.httpClient
            .post<any>(url, null)
            .pipe(
                retry(2),
                catchError((error: HttpErrorResponse) => {
                    console.error(error);
                    return throwError(error);
                })
            );
    }           
}