<div *ngIf="!adding && !editing" >

  <div class="row">
    <div class="col-8">
      <mat-form-field style="width:90%" autocomplete="off">
        <mat-label>Search</mat-label>
        <input matInput [formControl]="search" autocomplete="off" type="search">
      </mat-form-field>
    </div>
    <div class="col-3"><button class="btn btn-primary" (click)="addItem()">Add New Item</button></div>
    <div class="col-1">
      <i class='fas fa-window-close' style='font-size:24px;color:gray; float: right;' (click)="close()"></i>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <mat-dialog-content class="mat-typography">
        <h2 mat-dialog-title>Items</h2>
        <mat-selection-list #items [multiple]="false" (selectionChange)="selectionChange($event.option)">
          <div class="row">
            <div class="col-6">Item</div>
            <div class="col-5">Description</div>
            <div class="col-1"></div>
          </div>
          <hr>
          <mat-list-option style="height: auto" *ngFor="let item of $search|async" (click)="itemSelected(item)" [value]="item" [selected]="itemsControl.value &&itemsControl.value?.indexOf(item)>=0">
            
            <div class="row" style="height: auto; min-height: 40px;">
              <div class="col-6">{{item['item']}}</div>
              <div class="col-5">{{item['description']}}</div>
              <div class="col-1">
                
                <button style="height: 30px" class="btn btn-primary" (click)="editItem(item)">Edit</button>
              </div>
            </div>
          </mat-list-option>
        </mat-selection-list>
      </mat-dialog-content>
    </div>
  </div>
</div>

<!--  EDITING -->
<div *ngIf="editing">
  <div class="col-8"><h2 mat-dialog-title>Editing Item</h2></div>

  <hr>

  <mat-form-field style="width:60%" autocomplete="off">
    <mat-label>Item</mat-label>
    <input matInput autocomplete="off" [(ngModel)]="item">
  </mat-form-field>

  <mat-form-field style="width:60%" autocomplete="off">
    <mat-label>Description</mat-label>
    <input matInput autocomplete="off" [(ngModel)]="description">
  </mat-form-field>

  <div class="row">
    <div class="col-1">
      <button class="btn btn-primary" (click)="updateItem()">Save</button>
    </div>
    <div class="col-1">
      <button class="btn btn-danger" (click)="cancelItem()">Cancel</button>
    </div>
  </div>


</div>

<!-- ADDING -->
<div *ngIf="adding">
  <div class="col-8"><h2 mat-dialog-title>Adding New Item</h2></div>

  <hr>

  <mat-form-field style="width:60%" autocomplete="off">
    <mat-label>Item</mat-label>
    <input matInput autocomplete="off" [(ngModel)]="item">
  </mat-form-field>

  <mat-form-field style="width:60%" autocomplete="off">
    <mat-label>Description</mat-label>
    <input matInput autocomplete="off" [(ngModel)]="description">
  </mat-form-field>

  <div class="row">
    <div class="col-1">
      <button class="btn btn-primary" (click)="saveItem()">Save</button>
    </div>
    <div class="col-1">
      <button class="btn btn-danger" (click)="cancelItem()">Cancel</button>
    </div>
  </div>
</div>