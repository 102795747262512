<div *ngIf="loading" class="loading-container flex-content-center">
    <mat-progress-spinner color="primary" mode="indeterminate"></mat-progress-spinner>
</div>

<!-- put this into sidenav component
    should be <side-nav></side-nav>
 -->
<div id="sidenav" class="sidenav noprint" *ngIf="account">
    <div class="navlogo" *ngIf="!collapsed">
        <h1>Fifth Color PO</h1>
    </div>
    <nav>
        <div class="navgroup" *ngIf="!collapsed">
            <a class="snavlink" 
            routerLink="/orders/dashboard"
            [routerLinkActive]="['nav-active']"
            #nav1="routerLinkActive"
            
            >
                <span class="snlb1">Dashboard</span>
                <span class="snlb2">{{version}}</span>
            </a>
            <div class="navg2"  *ngIf="dataService.canComplete">
                <a class="snavlink" 
                routerLink="/orders/completedorders" 
                [routerLinkActive]="['nav-active']"
                #nav1Child1="routerLinkActive"
                >Completed Orders</a>
            </div>
            <div class="navg2" >
                <a class="snavlink" 
                routerLink="/orders/allorders" 
                [routerLinkActive]="['nav-active']"
                #nav1Child1="routerLinkActive"
                >All Orders</a>
            </div>

            <div class="navg2" *ngIf="dataService.canAdd">
                <a class="snavlink" 
                (click)="addOrder()"
                routerLink="#" 
                [routerLinkActive]="['nav-active']"
                #nav1Child1="routerLinkActive"
                >Add Order</a>
            </div>       

            <div class="navg2"  
            >
            <a class="snavlink" 
            (click)="logout()"
            routerLink="#" 
            [routerLinkActive]="['nav-active']"
            #nav1Child1="routerLinkActive"
            >Logout</a>
        </div>
        </div>

        <div class="navtop" style="margin-top: 100px">
            <div class="row">
                <a href="#" *ngIf="collapsed" (click)="showOrders()" class="content_img">
                    <i class='fa fa-list noprint' style='font-size:24px;color:white' ></i>
                    <div>Dashboard</div>
                </a> 
            </div>

            <div class="row"  *ngIf="dataService.canComplete">
                <a href="#" *ngIf="collapsed" (click)="showCompletedOrders()" class="content_img">
                    <i class='fa fa-check noprint' style='font-size:24px;color:white' ></i>
                    <div>Completed Orders</div>
                </a> 
            </div>

            <div class="row" >
                <a href="#" *ngIf="collapsed" (click)="showAllOrders()" class="content_img">
                    <i class='fa fa-money-check noprint' style='font-size:24px;color:white' ></i>
                    <div>All Orders</div>
                </a> 
            </div>            

            <div class="row" *ngIf="dataService.canAdd && router.url !=  '/orders/order'">
                <a href="#" *ngIf="collapsed" (click)="addOrder()" class="content_img">
                    <i class='fa fa-file-text-o noprint' style='font-size:24px;color:white'  ></i>
                    <div>Add Order</div>
                </a>
            </div>
            <div class="row">
                <a href="#" *ngIf="collapsed" (click)="logout()" class="content_img">
                    <i  class='fa fa-sign-out noprint' style='font-size:24px;color:gray' ></i>
                    <div>Logout</div>
                </a>   
            </div>
         

        
  
        </div>


        <div class="envmessage" *ngIf="!collapsed">
            <span>{{envMessage}}</span>
        </div>

 
        <div class="navbottom">
            <a href="" *ngIf="collapsed" (click)="expand()">
                <i class='fa fa-angle-double-right noprint' style='font-size:24px;color:white' ></i>
            </a>
            <a href="" *ngIf="!collapsed"  (click)="collapse()">
                <i  class='fa fa-angle-double-left noprint' style='font-size:24px;color:white'></i>
            </a>            

              
        </div>

    </nav>
</div>
<!-- Profile menu; header? Put this in its own component -->
<div [ngClass]="{'main pl-lg-4 container-fluid': account}">
    <div class="subnav" *ngIf="account">
        <div class="row">
            
            <div class="col-sm-5 col-xl-4" *ngIf="(router.url === '/orders/allorders')">
                <div class="sn-search" > 
                    <input id="searchInput" class="globalsearch" type="search" placeholder="PO#, Job Ticket, Requestor etc." [(ngModel)]="searchValue"/>
                    
                </div>
            </div>
        
            <div class="col-sm-11 col-xl-11">
                  <div class="sn-btns d-flex align-items-center justify-content-end">

                  <!--
                    <div class="accountDD ms-3 dropdown">
                        <button class="aDDbtn btn btn-common btn-white dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                            <span>{{account.user.firstname}}</span> 
                        </button>
                        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                            <<li><a class="dropdown-item" routerLink="/account/profile" routerLinkActive="active">Profile</a></li>
                            <li><a class="dropdown-item" (click)="logout()">Logout</a></li>
                        </ul>
                    </div>
                -->
                    <!--
                    <div class="accountDD ms-3 dropdown" *ngIf="(router.url === '/leads/dashboard' || router.url === '/leads/lead-view')">
                        <div class="accountDD ms-3 dropdown">
                            <button class="aDDbtn btn btn-common btn-white dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                <span>{{dataService.currentOrg.name}}</span>
                            </button>
                            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                <li *ngFor="let org of account.userorgs">
                                    <a class="dropdown-item" (click)="changeOrg(org)">{{org.name}}</a>
                                </li>
                        
                            </ul>
                        </div>
                    </div>
                    -->
                              
                </div>
            </div>
        </div>
       

       <!-- <div class="row" *ngIf="dataService.showSimpleDatePicker">
            <div class="col-lg-12">
                <rr-simpledatepicker></rr-simpledatepicker>
            </div>
        </div>-->
    </div>

    <!-- global alerts -->
    <alert></alert>
    
    <router-outlet></router-outlet>

          
    
 
</div>