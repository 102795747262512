import { Component } from '@angular/core';
import { DataService } from '@app/_services';
import { FormControl } from "@angular/forms";
import { debounceTime, switchMap, startWith } from "rxjs/operators";
import { of } from "rxjs";
import { MatDialog } from '@angular/material/dialog';
import { VendorsService } from '@app/_services';
import { map } from 'rxjs/operators';



@Component({
    selector: 'vendorpicklist',
    templateUrl: 'vendorpicklist.component.html',
  })
  export class VendorPicklistComponent {
    vendor_id = 0;
    vendor_nbr = '';
    vendor_name = '';
    vendor_street = '';
    vendor_city = '';
    vendor_state = '';
    vendor_postal = '';
    vendor_phone = '';
    vendor_fax = '';
    vendor_email = '';
    vendor_taxnumber = '';
    adding = false;
    editing = false;
    search = new FormControl();
    vendorsControl = new FormControl();
    constructor(
        public dataService: DataService,
        private vendorsService: VendorsService,
        public dialog: MatDialog
    ) {
        
        this.vendorsService.getVendors().pipe(
            map(vendors => {
                    this.dataService.vendorslist = vendors;
            }),
        ).subscribe();    
      

     }

    $search = this.search.valueChanges.pipe(
        startWith(null),
        debounceTime(200),
        switchMap((res: string) => {
            debugger;
            if (!res) return of(this.dataService.vendorslist);
            res = res.toLowerCase();
            return of(
                this.dataService.vendorslist.filter(x => (x.name.toLowerCase().indexOf(res) >= 0 
                    || x.city.toLowerCase().indexOf(res) >= 0
                    || x.state.toLowerCase().indexOf(res) >= 0
                    || x.vendor_nbr.toLowerCase().indexOf(res) >= 0)
                    )
            );
        })
    );
      selectionChange(option: any) {
        let value = this.vendorsControl.value || [];
        if (option.selected) value.push(option.value);
        else value = value.filter((x: any) => x != option.value);
        this.vendorsControl.setValue(value);
      }

    itemSelected(obj) {
        debugger;

        if (!this.editing) {
            this.dataService.selectedVendor = obj;
            this.dataService.dialogRef.close();
        }
    }

    addVendor() {
        this.vendor_nbr = '';
        this.vendor_name = '';
        this.vendor_street = '';
        this.vendor_city = '';
        this.vendor_state = '';
        this.vendor_postal = '';
        this.vendor_phone = '';
        this.vendor_fax = '';
        this.vendor_email = '';
        this.vendor_taxnumber = '';
        this.adding = true;


    }

    editVendor(vendor) {
        this.vendor_id = vendor.id;
        this.vendor_nbr = vendor.vendor_nbr;
        this.vendor_name = vendor.name;
        this.vendor_street = vendor.street;
        this.vendor_city = vendor.city;
        this.vendor_state = vendor.state;
        this.vendor_postal = vendor.postal;
        this.vendor_phone = vendor.phone;
        this.vendor_fax = vendor.fax;
        this.vendor_email = vendor.email;
        this.vendor_taxnumber = vendor.taxnumber;
        this.editing = true;
     }

     close() {
        this.dataService.dialogRef.close();
    }

    cancelVendor() {


        this.adding = false;
        this.editing = false;
    }

    delay(ms: number)
    {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    async updateVendor() {

        if (this.vendor_nbr === "") {
            alert("Vendor # is required");
            return;
        }

        if (this.vendor_name === "") {
            alert("Vendor Name is required");
            return;
        }

        var vendorObj = {
            vendor_nbr: this.vendor_nbr,
            name: this.vendor_name,
            street: this.vendor_street,
            city: this.vendor_city,
            state: this.vendor_state,
            postal: this.vendor_postal,
            phone: this.vendor_phone,
            fax: this.vendor_fax,
            email: this.vendor_email,
            taxnumber: this.vendor_taxnumber,

        }

        this.vendorsService.updateVendor(this.vendor_id, vendorObj).pipe(
            map( async vendor => {
                console.log(vendor);
            }),
        ).subscribe();

        await this.delay(500)
        this.editing = false;

        this.vendorsService.getVendors().pipe(
            map(vendors => {
                    this.dataService.vendorslist = vendors;
            }),
        ).subscribe();    


    
    }
    async saveVendor() {

        if (this.vendor_nbr === "") {
            alert("Vendor # is required");
            return;
        }

        if (this.vendor_name === "") {
            alert("Vendor Name is required");
            return;
        }

        var vendorObj = {
            vendor_nbr: this.vendor_nbr,
            name: this.vendor_name,
            street: this.vendor_street,
            city: this.vendor_city,
            state: this.vendor_state,
            postal: this.vendor_postal,
            phone: this.vendor_phone,
            fax: this.vendor_fax,
            email: this.vendor_email,
            taxnumber: this.vendor_taxnumber,
        }

        this.vendorsService.addVendor(vendorObj).pipe(
            map( async vendor => {
                console.log(vendor);
            }),
        ).subscribe();

        await this.delay(500)
        this.adding = false;

        this.vendorsService.getVendors().pipe(
            map(vendors => {
                    this.dataService.vendorslist = vendors;
            }),
        ).subscribe();  

    
    }

}