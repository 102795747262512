import { Component, OnInit , Input} from '@angular/core';
import { OrdersService } from '../../_services/orders.service';
import { AccountService } from '@app/_services';
import { DataService } from '@app/_services';
import { Orders } from '../../_models';
import { QueryOptions } from '@app/_models/query-opts';
import { Observable, Subject } from 'rxjs';
import { takeUntil, map } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import moment, { Moment } from 'moment';

@Component({
    templateUrl: 'allorders.component.html',
    providers: [OrdersService]
})


export class AllOrdersComponent implements OnInit {
    itemsperpage: number;
    currentpage: number;
    totalrecords: number;
    pageSize: number = 100;
    page: number = 1;
    totalPages: number;

    account = this.accountService.accountValue;
    orders: Orders;
    title: string;
    timestamp: string;
    datestamp: string;
    orders$: Observable<Orders>;
    viewtitle = "All Purchase Orders";
    canAdd: boolean;
    isPM: boolean;
    isFinal: boolean;


    queryOptions: QueryOptions = new QueryOptions();
    queryOptions2: QueryOptions = new QueryOptions();

    ranges: any = {
        Today: [moment(), moment()],
        Yesterday: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
        'Last 7 Days': [moment().subtract(6, 'days'), moment()],
        'Last 30 Days': [moment().subtract(29, 'days'), moment()],
        'This Month': [moment().startOf('month'), moment().endOf('month')],
        'Last Month': [
          moment()
            .subtract(1, 'month')
            .startOf('month'),
          moment()
            .subtract(1, 'month')
            .endOf('month')
        ],
        'Last 3 Month': [
          moment()
            .subtract(3, 'month')
            .startOf('month'),
          moment()
            .subtract(1, 'month')
            .endOf('month')
        ]
    };

    constructor(private accountService: AccountService,
            public dataService: DataService,
            private ordersService: OrdersService,
            private route: ActivatedRoute,
            private router: Router
        ) {
        this.queryOptions.page = 1;
        this.queryOptions.pageSize = 100;
        this.queryOptions.sortDirection = 'desc';
       
     
    }

   

    ngOnInit(): void {
        this.itemsperpage = 1000;
        this.currentpage = 1;

        localStorage.removeItem('purchaseorder'); 
        this.isPM = this.account.user.role === 'purchasingmanager';
        this.isFinal = this.account.user.role === 'approver';

        if (!this.dataService.startDate) {

            this.dataService.startDate = moment().subtract(6, 'days');
            this.dataService.endDate = moment();
       
            this.dataService.selectedDates = { startDate: this.dataService.startDate, endDate: this.dataService.endDate };
        }





        this.queryOptions.start = new Date(this.dataService.startDate);
        this.queryOptions.end = new Date(this.dataService.endDate);
        
        var term: string;
        var objValue: string;

        this.loadOrders();


        document.getElementById('searchInput').addEventListener('input', (e) => {
            console.log(`Input value: "${e.currentTarget['value']}"`);

              this.dataService.orders = Array.from(this.dataService.ordersOrig);
              this.dataService.orders = this.dataService.orders.filter((obj)=>{
                  return Object.keys(obj).reduce((acc, curr)=>{
                      term = e.currentTarget['value'].toLowerCase();
                      if (curr === 'status' || curr === 'requestorname' || curr === 'approver1name' || curr === 'approver2name' || curr === 'vendorname' || curr === 'jobs') {
                        if (obj[curr]) {
                            objValue = obj[curr].toLowerCase();
                            return acc || objValue.includes(term);
                        } else {
                          return acc;
                        }
                      } else if (curr === 'id' || curr =='requestdateformatted') {
                        objValue = obj[curr].toString();
                        return acc || objValue.includes(term);
                          
                      } else {
                          //objValue = obj[curr].toLowerCase();
                          //return acc || objValue.includes(term);
                          return acc;
                          
                      }

                  }, false);
              });

              console.log(this.dataService.orders)



       })


    }




    ngOnDestroy(): void {        

    }

    pageSizeChange(event) {
        this.pageSize = event;
      }
    
    handlePageChange(event) {
    this.queryOptions.page = event;
    this.queryOptions.pageSize = this.pageSize;
    this.currentpage = event;
    // this.refreshLeads(this.queryOptions.sortOrder);  
    }

    loadOrders() {
        this.queryOptions.sortOrder = 'id';
        this.queryOptions.sortDirection = 'desc';
        this.queryOptions.pageSize = 1000000;
        
 
 
        this.viewtitle = 'All Purchase Orders';
        this.ordersService.getAllNotDraftPurchaseOrders(this.queryOptions ).pipe(
        //    this.ordersService.getMyPreviousFinalApprovals(this.queryOptions, this.account.user.id ).pipe(                    
            map(orders => {
                    this.dataService.orders = orders["orders"];
                    this.dataService.ordersOrig = Array.from(this.dataService.orders);
            }),
        ).subscribe();           

    
      
      }
      sortLeads(sortOrder) {
      }

      addOrder() {

        this.dataService.order = null;
        this.router.navigate(['/orders/order'], { relativeTo: this.route })
      }


      editOrder(order) {
        this.dataService.order = order;
        this.router.navigate(['/orders/order'], { relativeTo: this.route })
      }
/*
    sortLeads(sortOrder) {
        // is this a new sort order?
        if (this.queryOptions.sortOrder === sortOrder) {
            if (this.queryOptions.sortDirection === 'asc') {
                this.queryOptions.sortDirection = 'desc';
            } else {
                this.queryOptions.sortDirection = 'asc';
            }
        } else {
            this.queryOptions.sortDirection = 'desc';
        }
        this.queryOptions.sortOrder = sortOrder;

        $(`#company_employees_sort`).removeClass('btn-sort');
        $(`#company_revenue_sort`).removeClass('btn-sort');
        $(`#page_count_sort`).removeClass('btn-sort');
        $(`#session_count_sort`).removeClass('btn-sort');

        $(`#company_employees_sort`).removeClass('btn-sort-asc');
        $(`#company_revenue_sort`).removeClass('btn-sort-asc');
        $(`#page_count_sort`).removeClass('btn-sort-asc');
        $(`#session_count_sort`).removeClass('btn-sort-asc');

        $(`#company_employees_sort`).addClass('btn-hollow');
        $(`#company_revenue_sort`).addClass('btn-hollow');
        $(`#page_count_sort`).addClass('btn-hollow');
        $(`#session_count_sort`).addClass('btn-hollow');

        if (this.queryOptions.sortDirection === 'desc') {
            $(`#${sortOrder}_sort`).addClass('btn-sort');
            if (sortOrder==='company_employees' || sortOrder==='company_revenue') {
              this.dataService.companyLeads.results.sort((a, b) => (parseInt(a[sortOrder].split(" ")[0].replace(",","")) < parseInt(b[sortOrder].split(" ")[0].replace(",",""))) ? 1 : -1)
            } else {
              this.dataService.companyLeads.results.sort((a, b) => (a[sortOrder] < b[sortOrder]) ? 1 : -1)
            }
    
        } else {
            $(`#${sortOrder}_sort`).addClass('btn-sort-asc');
            if (sortOrder==='company_employees' || sortOrder==='company_revenue') {
              this.dataService.companyLeads.results.sort((a, b) => (parseInt(a[sortOrder].split(" ")[0].replace(",","")) > parseInt(b[sortOrder].split(" ")[0].replace(",",""))) ? 1 : -1)
            } else {
              this.dataService.companyLeads.results.sort((a, b) => (a[sortOrder] > b[sortOrder]) ? 1 : -1)
            }
        }
        $(`#${sortOrder}_sort`).removeClass('btn-hollow');
    


    }
*/


     
}