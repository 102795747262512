import { Component } from '@angular/core';
import { FormControl } from "@angular/forms";
import { debounceTime, switchMap, startWith } from "rxjs/operators";
import { of } from "rxjs";
import { DataService, ItemsService } from '@app/_services';
import { map } from 'rxjs/operators';
@Component({
    selector: 'picklist',
    templateUrl: 'picklist.component.html',
  })
  export class PicklistComponent {
    search = new FormControl();
    itemsControl = new FormControl();
    adding = false;
    editing = false;
    item='';
    itemid=0;
    description='';
    constructor(
        public dataService: DataService,
        private itemsService: ItemsService
    ) { 
        this.itemsService.getItems().pipe(
            map(items => {
                    this.dataService.itemslist = items["items"];
            }),
        ).subscribe();
    }

    $search = this.search.valueChanges.pipe(
        startWith(null),
        debounceTime(200),
        switchMap((res: string) => {
            debugger;
            if (!res) return of(this.dataService.itemslist);
            res = res.toLowerCase();
            return of(
                this.dataService.itemslist.filter(x => (x.item.toLowerCase().indexOf(res) >= 0 || x.description.toLowerCase().indexOf(res) >= 0))
            );
        })
    );

    delay(ms: number)
    {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

      selectionChange(option: any) {
        let value = this.itemsControl.value || [];
        if (option.selected) value.push(option.value);
        else value = value.filter((x: any) => x != option.value);
        this.itemsControl.setValue(value);
      }



    itemSelected(obj) {
        debugger;
        this.dataService.selecteditem = obj.item;
        this.dataService.selecteditemid = obj.id;
        this.dataService.selecteditemdescription = obj.description;
        this.dataService.dialogRef.close();
    }

    close() {
        this.dataService.dialogRef.close();
    }


    addItem() {
        this.item = '';
        this.description = '';     
        this.adding = true;
    }

    editItem(item) {
     
        this.item = item.item;
        this.description = item.description;
        this.itemid = item.id;
        this.editing = true;
     }

    cancelItem() {


        this.adding = false;
        this.editing = false;
    }

    async saveItem() {
        if (this.item === "") {
            alert("Item is required");
            return;
        }

        var itemObj = {
            item: this.item,
            description: this.description
        }

        this.itemsService.addItem(itemObj).pipe(
            map( async item => {

            }),
        ).subscribe();

        await this.delay(500)
        this.adding = false;

        this.itemsService.getItems().pipe(
            map(items => {
                    this.dataService.itemslist = items["items"];;
            }),
        ).subscribe();    

    }

    async updateItem() {
        if (this.item === "") {
            alert("Item is required");
            return;
        }

        var itemObj = {
            item: this.item,
            description: this.description
        }

        this.itemsService.updateItem(this.itemid, itemObj).pipe(
            map( async item => {

            }),
        ).subscribe();

        await this.delay(500);
        this.editing = false;
        this.itemsService.getItems().pipe(
            map(async items => {
                    this.dataService.itemslist = items["items"];;
            }),
        ).subscribe();    

    }

}