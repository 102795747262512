<div class="row noscreen">
    <div class="col-lg-12">
        <div class="card">
            <div class="row justify-content-between">
                <div class="col-5">
                    <label for="vendor">
                        Vendor
                    </label>

                    <div class="form-group">
                        <!--<form-companyName-editor></form-companyName-editor>              -->
                        <div class="d-flex align-items-center" >
                            <input type="text" class="form-control editable spacer-r-10" [(ngModel)]="dataService.order.vendorname"  placeholder="not assigned" id="vendorname"/>
                            <button class="btn btn-primary noprint" (click)="openVendorDialog()" *ngIf="canSelectVendor">Select</button>
                        </div>

                        <div class="d-flex align-items-center" >
                            <textarea rows=3 type="text" class="form-control editable"  placeholder="not assigned" [value]="dataService.order.fulladdress" id="vendoraddress">
                            </textarea> 
                        </div>
                        <label for="shipto">
                            Ship To
                          </label>
                        <div class="form-group">
                            <!--<form-companyName-editor></form-companyName-editor>              -->
                            <div class="d-flex align-items-center" >
                                <input type="text" class="form-control editable"  [(ngModel)]="dataService.order.shipto" id="shipto"/>
                            </div>
                            <div class="d-flex align-items-center" >
                                <textarea rows=3 type="text" class="form-control editable"  [(ngModel)]="dataService.order.shiptoaddress" id="shiptoaddress">
                                </textarea> 
                            </div>
                        </div>  
                    </div>         
                </div>
                <div class="col-1"></div>
                <div class="col-6">
                    <div class="form-group">
                        <div class="row">
                            <div class="col-11">
                                <img src="https://fifthcolor.com/rsc/img/logos/wordmark-dark.svg" width="300px"/>
                            </div>
                            <div class="col-1">
                                
                                <i *ngIf="dataService.order.id" class='fas fa-file-medical noprint' style='font-size:36px;color:gray' (click)="showNotes()"></i>

                            </div>
                        </div>
                        <div class="row">
                            &nbsp;
                        </div>
                        <div class="row">
                            <div class="d-flex align-items-center" >
                                <h2>Purchase Order - {{dataService.order.id}}</h2>
                            </div>
                        </div>
                    </div>    
                    
                    <div class="form-group">
                        <div class="d-flex align-items-center">
                            <div class="col-3">Status: </div>
                            <div class="col-9">
                                <span type="text" class="form-control editable"  id="status">{{dataService.order.status}}</span>
                            </div>
                        </div>     

                        <!--<form-companyName-editor></form-companyName-editor>              -->
                        <div class="d-flex align-items-center" *ngIf="!neworder">  
                            <div class="col-3">Order Date: </div>
                            <div class="col-9">
                                <input type="text" class="form-control editable"  value="{{dataService.order.po_date | date: 'MM/dd/yyyy'}}" placeholder="Date" id="orderdate"/>
                            </div>
                        </div>
                        <div class="d-flex align-items-center">
                            <div class="col-3">Requester: </div>
                            <div class="col-9">
                                <span type="text" class="form-control editable"  id="requestorname">{{dataService.order.requestorname}}</span>
                            </div>
                        </div>                            
                        <div class="d-flex align-items-center" *ngIf="neworder">
                            <div class="col-3">Request Date: </div>
                            <div class="col-9">
                                <input type="text" class="form-control editable"   value="{{dataService.order.requestdate | date: 'MM/dd/yyyy'}}" placeholder="Date" id="requestdate"/>
                            </div>
                        </div>          
                        <div class="d-flex align-items-center" *ngIf="showPM">
                            <div class="col-3">Purchasing Manager: </div>
                            <div class="col-9">
                                <span type="text" class="form-control editable" disabled  id="approver1name">{{dataService.order.approver1name}}</span>
                            </div>
                        </div>                            
                        <div class="d-flex align-items-center" *ngIf="showFinal">
                            <div class="col-3">Final Approver: </div>
                            <div class="col-9">
                                <span type="text" class="form-control editable" disabled  id="approver2name">{{dataService.order.approver2name}}</span>
                            </div>
                        </div>
                        <div class="spacer-t-20"></div>                            
                       
                    </div>             
                </div>
            </div>  
        </div>
    </div> 
    <div>&nbsp;
    </div>
    <div class="card">
        <table class="table smaller-input-text table-bordered">
            <tbody>
                <tr>
                    <td scope="col">
                        Vendor Ph: {{dataService.order.phone}}
                    </td>   
                    <td scope="col">
                        Tax No: {{dataService.order.taxnumber}}
                    </td>   
                    <td scope="col">
                        FOB: {{dataService.order.fob}}
                    </td>   
                    <td scope="col">
                        Ship To Ph: {{dataService.order.shiptophone}}
                    </td>   
                    <td scope="col">
                        Emailed: {{dataService.order.emailed}}
                    </td>   
                </tr>

                <tr>
                    <td scope="col">
                        Vendor Fax: {{dataService.order.fax}} 
                    </td>   
                    <td scope="col">
                        Terms: {{dataService.order.terms}}
                    </td>   
                    <td scope="col">
                        Ship Via: {{dataService.order.shipvia}}  
                    </td>   
                    <td scope="col">
                        Ship To Fax: {{dataService.order.shiptofax}}
                    </td>   
                    <td scope="col">
                        Requester: {{dataService.order.requestorname}}
                    </td>   
                </tr>
            </tbody>
        </table>

       
    </div> 



    <div>&nbsp;
    </div>
    <div class="card" >


        <table class="table small-input-text table-bordered">
            <thead style="background-color:rgb(142, 218, 243)">
                <tr>
                    <td scope="col" class="col-1" style="width: 2%">
                        <span>#</span>
                    </td>
                    <td scope="col" class="col-2" style="width: 20%">
                        <span>Item/Description</span>
                        
                    </td>
                    <td scope="col"  class="col-1">
                        <span>Job Ticket #</span>
                    </td>
                    <td scope="col"  class="col-1">
                        <span>GL Code</span>
                    </td>                        
                    <td scope="col" class="col-1">
                            <span>Due Date</span>
                    </td>
                    <td scope="col" class="col-1">
                            <span>Unit Price</span>
                    </td>
                    <td scope="col" class="col-1">
                            <span>UOM</span>
                    </td>
                    <td scope="col" class="col-1">
                            <span>Quantity</span>
                    </td>
                    <td scope="col" class="col-1">
                            <span>Amount</span>
                    </td>


                </tr>
            </thead>
            <tbody>
                <!--<tr *ngFor="let lead of companyLeads.results | leadSort:'page_count'" [class.is-isp]="lead.isisp" routerLink="/lead-breakdown">-->
                <!--<tr *ngFor="let lead of companyLeads.results" [class.is-isp]="lead.isisp" routerLink="/lead-breakdown"> -->
                <!--<tr *ngFor="let lead of dataService.companyLeads.results" [class.is-isp]="lead.isisp" routerLink="/leads/lead-breakdown"> -->
                <!--<tr *ngFor="let order of dataService.items | paginate: { itemsPerPage: 5, currentPage: 1 }">       -->
                <tr *ngFor="let item of dataService.order.items; index as i" class="small-input-text" >       
                    <td > {{ i+1 }}</td>
                    <!--<td *ngIf="item.source=='database'" style="color: red" > {{ item.description }}<br>{{ item.notes }}</td>
                    <td *ngIf="!item.source=='database'" > {{ item.description }}<br>{{ item.notes }}</td>-->
                    <td> {{ item.item }}<br>{{ item.description }}</td>
                    <td> {{ item.job }} </td>
                    <td> {{ item.glcode }} </td>
                    <td> {{item.duedate  |  date}}                                              
                    </td> 
                    <td> {{ item.price | currency}} </td>
                    <td> {{ item.uom}} </td>
                    <td> {{ item.qty}} </td>
                    <td> {{ item.total_price | currency}} </td>


                </tr>

            </tbody>
        </table>
    </div>

    <div class="card table-card spacer-t-10" >
        <table class="table">
            <tbody>
                <tr>
                    <td rowspan=7 style="width: 40%">
                        Notes<br>
                        {{this.dataService.order.ponotes}}
                    </td>
                    <td scope="col" class="col-1" style="width: 10%">
                        Freight
                    </td>
                    <td scope="col" class="col-1" style="width: 10%">
                        {{this.dataService.order.freight | currency}}
                    </td>

                </tr>
                <tr class="spacer-t-20">
                </tr>
                
                <tr>
                    <td scope="col" class="col-1" style="width: 10%">
                        Subtotal
                    </td>
                    <td scope="col" class="col-1" style="width: 10%">
                        {{this.dataService.order.subtotal | currency}}
                    </td>
                </tr>

                <tr>
                    <td  scope="col" class="col-1" style="width: 10%">
                        Tax  - {{this.dataService.order.taxpercent | percent: '1.2'}}
                    </td>

                    <td scope="col" class="col-1" style="width: 10%">
                        {{this.dataService.order.tax | currency}}
                    </td>
                </tr>      
                <tr>
                    <td scope="col" class="col-1" style="width: 10%">
                        Total
                    </td>
                    <td scope="col" class="col-1" style="width: 10%">
                        {{this.dataService.order.total | currency}}
                    </td>
                </tr>    
                <tr>
                    <td scope="col" class="col-1" style="width: 10%">
                        Amount Paid
                    </td>
                    <td scope="col" class="col-1" style="width: 10%">
                        {{this.dataService.order.paid | currency}}
                    </td>
                </tr>          
                <tr>
                    <td scope="col" class="col-1" style="width: 10%">
                        P.O. Total
                    </td>
                    <td scope="col" class="col-1" style="width: 10%">
                        {{this.dataService.order.pototal | currency}}
                    </td>
                </tr>                    
            </tbody>
        </table>
    </div>    
    
    
</div>
<div class="row noprint">
    <div class="col-lg-12">
        <div class="card table-card">
            <!--<div [formGroup]="orderForm">-->
            <div>

                <div class="row justify-content-between">
                    <div class="col-5">
                        <label for="vendor">
                            Vendor
                          </label>
                        <div class="form-group">
                            <!--<form-companyName-editor></form-companyName-editor>              -->
                            <div class="d-flex align-items-center" >
                                <input type="text" class="form-control editable spacer-r-10" disabled  [(ngModel)]="dataService.order.vendorname"  placeholder="not assigned" id="vendorname"/>
                                <button class="btn btn-primary noprint" (click)="openVendorDialog()" *ngIf="canSelectVendor">Select</button>
                            </div>

                            <div class="d-flex align-items-center" >
                                <textarea rows=3 type="text" class="form-control editable" disabled  placeholder="not assigned" [value]="dataService.order.fulladdress" id="vendoraddress">
                                </textarea> 
                            </div>
                        </div>                        
                        <label for="shipto">
                            Ship To
                          </label>
                        <div class="form-group">
                            <!--<form-companyName-editor></form-companyName-editor>              -->
                            <div class="d-flex align-items-center" >
                                <input type="text" class="form-control editable"  [(ngModel)]="dataService.order.shipto" id="shipto"/>
                            </div>
                            <div class="d-flex align-items-center" >
                                <textarea rows=3 type="text" class="form-control editable" [(ngModel)]="dataService.order.shiptoaddress" id="shiptoaddress">
                                </textarea> 
                            </div>
                        </div>                                                
                    </div>

                    <div class="col-1"></div>
                    <div class="col-6">
                        <div class="form-group">
                            <div class="row">
                                <div class="col-11">
                                    <img src="https://fifthcolor.com/rsc/img/logos/wordmark-dark.svg" width="300px"/>
                                </div>
                                <div class="col-1">
                                    
                                    <i *ngIf="dataService.order.id" class='fas fa-file-medical noprint' style='font-size:36px;color:gray' (click)="showNotes()"></i>

                                </div>
                            </div>
                            <div class="row">
                                &nbsp;
                            </div>
                            <div class="row">
                                <div class="d-flex align-items-center" >
                                    <h2>Purchase Order - {{dataService.order.id}}</h2>
                                </div>
                            </div>
                        </div>    
                        
                        <div class="form-group">
                            <div class="d-flex align-items-center">
                                <div class="col-3">Status: </div>
                                <div class="col-9">
                                    <span type="text" class="form-control editable" disabled  id="status">{{dataService.order.status}}</span>
                                </div>
                            </div>     

                            <!--<form-companyName-editor></form-companyName-editor>              -->
                            <div class="d-flex align-items-center" *ngIf="!neworder">  
                                <div class="col-3">Order Date: </div>
                                <div class="col-9">
                                    <input type="text" class="form-control editable" disabled  value="{{dataService.order.po_date | date: 'MM/dd/yyyy'}}" placeholder="Date" id="orderdate"/>
                                </div>
                            </div>
                            <div class="d-flex align-items-center">
                                <div class="col-3">Requester: </div>
                                <div class="col-9">
                                    <span type="text" class="form-control editable" disabled  id="requestorname">{{dataService.order.requestorname}}</span>
                                </div>
                            </div>                            
                            <div class="d-flex align-items-center" *ngIf="neworder">
                                <div class="col-3">Request Date: </div>
                                <div class="col-9">
                                    <input type="text" class="form-control editable" disabled  value="{{dataService.order.requestdate | date: 'MM/dd/yyyy'}}" placeholder="Date" id="requestdate"/>
                                </div>
                            </div>          
                            <div class="d-flex align-items-center" *ngIf="showPM">
                                <div class="col-3">Purchasing Manager: </div>
                                <div class="col-9">
                                    <span type="text" class="form-control editable" disabled  id="approver1name">{{dataService.order.approver1name}}</span>
                                </div>
                            </div>                            
                            <div class="d-flex align-items-center" *ngIf="showFinal">
                                <div class="col-3">Final Approver: </div>
                                <div class="col-9">
                                    <span type="text" class="form-control editable" disabled  id="approver2name">{{dataService.order.approver2name}}</span>
                                </div>
                            </div>
                            <div class="spacer-t-20"></div>                            
                            <button (click)="savePO()" class="btn btn-primary spacer-r-5 noprint" *ngIf="canSave">Save</button>
                            <button (click)="submitPO()" class="btn btn-primary spacer-r-5 noprint" *ngIf="canSubmit">Submit</button>
                            <button (click)="submitAndApprovePO()" class="btn btn-primary spacer-r-5 noprint" *ngIf="canSubmit && isPM">Submit and Approve</button>
                            <button (click)="approvePO()" class="btn btn-primary spacer-r-5 noprint" *ngIf="canApprove && isPM">Approve</button>
                            <button (click)="approveFinalPO()" class="btn btn-primary spacer-r-5 noprint" *ngIf="canApprove && isFinal && dataService.order.status!='completed' && dataService.order.status!='approved'">Final Approval</button> 
                            <button (click)="submitAndApproveFinalPO()" class="btn btn-primary spacer-r-5 noprint" *ngIf="canSubmit && isFinal">Submit and Approve</button>                           
                            <button (click)="returnPOToRequestor()" class="btn btn-warning spacer-r-5 noprint" *ngIf="canApprove && isPM">Return To Requestor</button>
                            <button (click)="returnPOToPM()" class="btn btn-warning spacer-r-5 noprint" *ngIf="canApprove && isFinal && dataService.order.status==='initial approval'">Return to Purchasing Manager</button>
                            <button (click)="denyPO()" class="btn btn-danger spacer-r-5 noprint" *ngIf="canApprove">Deny</button>
                            <button (click)="updatePO()" class="btn btn-primary spacer-r-5 noprint" *ngIf="canUpdate">Update</button>
                            <button (click)="deletePO()" class="btn btn-danger spacer-r-5 noprint" *ngIf="canDelete">Delete</button>
                            <p><mat-checkbox (change)="markReceived($event)" [checked]="dataService.order.received" formControlName="received" *ngIf="canComplete" class="btn">Mark Received {{dataService.order.datereceived  | date: 'medium'}}</mat-checkbox></p>
                        </div>             
                    </div>

                </div>
                
            </div> 
        </div>
        <div>&nbsp;
        </div>
        <div class="card table-card">
            <div class="row small-input-text">
                <div class="col-1">
                    Vendor Ph:
                </div>
                <div class="col">
                    {{dataService.order.phone}}
                </div>

                <div class="col-1">
                    Tax No:
                </div>
                <div class="col">
                    {{dataService.order.taxnumber}}
                </div>

                <div class="col-1">
                    FOB: 
                </div>
                <div class="col">
                    <input autocomplete="off" type="text" class="form-control small-input" id="fob" required [(ngModel)]="dataService.order.fob" name="fob">
                </div>
                <div class="col-1">
                    Ship To Ph: 
                </div>
                <div class="col">
                    <input autocomplete="off" type="text" class="form-control small-input" id="shiptophone" required [(ngModel)]="dataService.order.shiptophone" name="shiptophone">
                </div>                
                <div class="col-1">
                    Emailed: 
                </div>
                <div class="col">
                    <input autocomplete="off" type="text" class="form-control small-input" id="emailed" required [(ngModel)]="dataService.order.emailed" name="emailed">
                </div>                           
            </div>
            <div class="row small-input-text">
                <div class="col-1">
                    Vendor Fax:  
                </div>
                <div class="col">
                    {{dataService.order.fax}}
                </div>                
                <div class="col-1">
                    Terms:
                </div>
                <div class="col">
                    <input autocomplete="off" type="text" class="form-control small-input" id="terms" required [(ngModel)]="dataService.order.terms" name="terms">
                </div>
                <div class="col-1">
                    Ship Via:   
                </div>           
                <div class="col">
                    <input autocomplete="off" type="text" class="form-control small-input" id="shipvia" required [(ngModel)]="dataService.order.shipvia" name="shipvia">
                </div>                
                <div class="col-1">
                    Ship To Fax:   
                </div>
                <div class="col">
                    <input autocomplete="off" type="text" class="form-control small-input" id="shiptofax" required [(ngModel)]="dataService.order.shiptofax" name="shiptofax">
                </div>           
                <div class="col-1">
                    Requester:
                </div>                     
                <div class="col">
                    {{dataService.order.requestorname}} 
                </div>                           
            </div>
        </div>
        <div>&nbsp;
        </div>

        <div class="card table-card">
            <div class="row small-input-text pad-bottom-20">
                <div class="col-2" >
                    <mat-checkbox [checked]="dataService.order.digitalinventorychecked" (change)="dataService.order.digitalinventorychecked = !dataService.order.digitalinventorychecked" formControlName="digitalinventorychecked">Was Digital Inventory Checked?</mat-checkbox>
                </div>

                <div class="col-2">
                    <mat-checkbox [checked]="dataService.order.physicalinventorychecked" (change)="dataService.order.physicalinventorychecked = !dataService.order.physicalinventorychecked" formControlName="physicalinventorychecked">Was Physical Inventory Checked?</mat-checkbox>
                </div>

                <div class="col-2">
                    <mat-checkbox [checked]="dataService.order.price" (change)="dataService.order.price = !dataService.order.price" formControlName="price">Price</mat-checkbox>
                </div>
                <div class="col-4">
                    * Price for Material less than the price charged to customer (40%)
                </div>
            </div>
            <div class="row small-input-text pad-bottom-15"> 
                                
                <div class="col-2">
                    When is the Material Needed 
                </div>
                <div class="col-2">
                        <input disabled matInput [matDatepicker]="picker5" style="width: 70%" id="whenneeded" [(ngModel)]="dataService.order.whenneeded" name="whenneeded">
                        <mat-datepicker-toggle matSuffix [for]="picker5"></mat-datepicker-toggle>
                        <mat-datepicker style="color: white" #picker5 disabled="false" class="noprint"></mat-datepicker>

                </div>

                <div class="col-2">
                    When was the Order Placed:
                </div>
                <div class="col-2">
                    <input disabled matInput [matDatepicker]="picker2" style="width: 70%" id="whenplaced" [(ngModel)]="dataService.order.whenplaced" name="whenplaced">
                    <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                    <mat-datepicker style="color: white" #picker2 disabled="false" class="noprint"></mat-datepicker>
                </div>             
                <div class="col-2">
                    What is Material Expected Delivery Date:
                </div>
                <div class="col-2">
                    <input disabled matInput [matDatepicker]="picker3" style="width: 70%" id="expecteddate" [(ngModel)]="dataService.order.expecteddate" name="expecteddate">
                    <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
                    <mat-datepicker style="color: white" #picker3 disabled="false" class="noprint"></mat-datepicker>
                </div>                                             
            </div>
            <div class="row small-input-text pad-bottom-15">
                <div class="col-2">
                    Material Arrival Date:
                </div>
                <div class="col-2">
                    <input disabled matInput [matDatepicker]="picker4" style="width: 70%" id="arrivaldate" [(ngModel)]="dataService.order.arrivaldate" name="arrivaldate">
                    <mat-datepicker-toggle matSuffix [for]="picker4"></mat-datepicker-toggle>
                    <mat-datepicker style="color: white" #picker4 disabled="false" class="noprint"></mat-datepicker>
                </div>                
                <div class="col-2">
                    Bin/Rack Location:
                </div>
                <div class="col-2">
                    <input autocomplete="off" type="text" class="form-control small-input" id="bin" required [(ngModel)]="dataService.order.bin" name="bin">
                </div>             
                                
            </div>            
        </div>
        <div>&nbsp;
        </div>


        <div class="card table-card" >


            <table class="table table-striped table-borderless">
                <thead style="background-color:rgb(142, 218, 243)">
                    <tr>
                        <td scope="col" class="col-1" style="width: 2%">
                            <span>#</span>
                        </td>
                        <td scope="col" class="col-2" style="width: 20%">
                            <span>Item/Description</span>
                            
                        </td>
                        <td scope="col"  class="col-1">
                            <span>Job Ticket #</span>
                        </td>
                        <td scope="col"  class="col-1">
                            <span>GL Code</span>
                        </td>                        
                        <td scope="col" class="col-1">
                                <span>Due Date</span>
                        </td>
                        <td scope="col" class="col-1">
                                <span>Unit Price</span>
                        </td>
                        <td scope="col" class="col-1">
                                <span>UOM</span>
                        </td>
                        <td scope="col" class="col-1">
                                <span>Quantity</span>
                        </td>
                        <td scope="col" class="col-1">
                                <span>Amount</span>
                        </td>
                        <td scope="col" class="col-1" style="width: 2%">
                         
                        </td>
                        <td scope="col" class="col-1" style="width: 2%">
                         
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <!--<tr *ngFor="let lead of companyLeads.results | leadSort:'page_count'" [class.is-isp]="lead.isisp" routerLink="/lead-breakdown">-->
                    <!--<tr *ngFor="let lead of companyLeads.results" [class.is-isp]="lead.isisp" routerLink="/lead-breakdown"> -->
                    <!--<tr *ngFor="let lead of dataService.companyLeads.results" [class.is-isp]="lead.isisp" routerLink="/leads/lead-breakdown"> -->
                    <!--<tr *ngFor="let order of dataService.items | paginate: { itemsPerPage: 5, currentPage: 1 }">       -->
                    <tr *ngIf="showadd">     

                        <td><button mat-button class="btn btn-primary spacer-t-10 noprint" (click)="openDialog()">Lookup</button></td>
                        <td><input type="text" disabled id="item" [(ngModel)]="item.item" name="item" ngxTypeahead class="col-sm-12 form-control"
                            [taList]="dataService.itemslist" 
                            [taListItemField]="['item']"
                            taListItemLabel="item"
                            (taSelected)="handleStaticResultSelected($event)">
                            <br>

                            <textarea class="form-control" id="descriptionb" required [(ngModel)]="item.description" name="description" disabled></textarea>

                            <!--<pre><code>{{ itemsList | json }}</code></pre>-->
                        </td>
                        <td><input autocomplete="off" type="text" class="form-control" id="job" required [(ngModel)]="item.job" name="job"></td>
                        <td><input autocomplete="off" type="text" id="glcode" [(ngModel)]="item.glcode" name="glcode" /></td>
                        <!--<td><input type="text" id="duedate" [(ngModel)]="item.duedate" name="duedate" /></td>-->
                        <td style="width: 15%">
                            <input disabled matInput [matDatepicker]="picker" style="width: 70%" id="duedate" [(ngModel)]="item.duedate" name="duedate">
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-datepicker style="color: white" #picker disabled="false" class="noprint"></mat-datepicker>
                        </td>                            
                        <td><input autocomplete="off" type="text" id="price" [(ngModel)]="item.price" name="price" /></td>
                        <td>
                            <!--<input autocomplete="off" type="text" id="uom" [(ngModel)]="item.uom" name="uom" />-->

                                <select matNativeControl required id="uom" [(ngModel)]="item.uom" name="uom">
                                  <option value="ea">ea</option>
                                  <option value="m">m</option>

                                </select>

                        </td>
                        <td><input autocomplete="off" type="text" id="qty" [(ngModel)]="item.qty" name="qty" /></td>
                        <td><input type="text" disabled id="total_price" [(ngModel)]="item.total_price" name="total_price" /></td>

                    </tr>
                    <tr>
                        <td colspan=11>
                            <button *ngIf="!showadd && canSave" (click)="addItem()" class="btn btn-primary noprint">Add</button>
                            <button *ngIf="showadd" (click)="saveItem()" class="btn btn-primary spacer-r-5 noprint">Save</button>
                            <button *ngIf="showadd" (click)="cancelItem()" class="btn btn-secondary noprint">Cancel</button>
                        </td>       
                         
                    </tr>
                    <tr *ngFor="let item of dataService.order.items; index as i" >       
                        <td > {{ i+1 }}</td>
                        <!--<td *ngIf="item.source=='database'" style="color: red" > {{ item.description }}<br>{{ item.notes }}</td>
                        <td *ngIf="!item.source=='database'" > {{ item.description }}<br>{{ item.notes }}</td>-->
                        <td class="item_{{item.source}}"> {{ item.item }}<br>{{ item.description }} </td>
                        <td> {{ item.job }} </td>
                        <td> {{ item.glcode }} </td>
                        <td >
                            <input disabled matInput [matDatepicker]="picker" id="duedate" [(ngModel)]="item.duedate" name="duedate">
                        </td> 
                        <td> {{ item.price}} </td>
                        <td> {{ item.uom}} </td>
                        <td> {{ item.qty}} </td>
                        <td> {{ item.total_price | currency: '$':'symbol':'1.0-9'}} </td>
                        <td  *ngIf="item.source==='deleted'" class="item_deleted"> (deleted)</td>
                        <td  *ngIf="item.source!='deleted' && !showadd && canSave"><button class="btn btn-primary noprint" (click)="editItem(item)">edit</button></td>
                        <td  *ngIf="item.source!='deleted' && !showadd && canSave "><button class="btn btn-danger noprint" (click)="removeItem(item)">remove</button> </td>

                        <td *ngIf="item.arrived && item.source=='database'" (click)="unarrived(item)">{{item.arrived | date: 'MM/dd/yyyy'}}</td>
                        <td *ngIf="!item.arrived  && item.source=='database'"> <button id="{{item.id}}" (click)="arrived(item)" class="btn btn-success spacer-r-1 noprint">Arrived?</button></td>
                    </tr>

                </tbody>
            </table>


        </div>



        <div class="card table-card spacer-t-10" >


            <table class="table table-borderless">
                <tbody>
                    <tr>
                        <td rowspan=7 style="width: 40%" *ngIf="canFreightTax"  >
                            Notes<br>
                            <textarea cols=50 rows=10 [(ngModel)]="this.dataService.order.ponotes" name="ponotes" ></textarea>
                        </td>
                        <td rowspan=7 style="width: 40%" *ngIf="!canFreightTax" >
                            Notes<br>
                            {{dataService.order.ponotes}}
                            
                        </td>
                        <td scope="col" class="col-1" style="width: 10%">
                            Freight
                        </td>
                        <td *ngIf="!canFreightTax" scope="col" class="col-1" style="width: 10%">
                            {{this.dataService.order.freight | currency}}
                        </td>
                        <td *ngIf="canFreightTax" scope="col" class="col-1" style="width: 10%">
                            
                            <input  style="width: 80px" autocomplete="off" type="number" id="freight" (ngModelChange)="modelChangeFn($event)" [(ngModel)]="this.dataService.order.freight" name="freight" />
                        </td>                        
                    </tr>
                    <tr class="spacer-t-20">
                    </tr>
                    
                    <tr>
                        <td scope="col" class="col-1" style="width: 10%">
                            Subtotal
                        </td>
                        <td scope="col" class="col-1" style="width: 10%">
                            {{this.dataService.order.subtotal | currency}}
                        </td>
                    </tr>

                    <tr>
                        <td *ngIf="!canFreightTax" scope="col" class="col-1" style="width: 10%">
                            Tax  - {{this.dataService.order.taxpercent | percent: '1.2'}}
                        </td>
                        <td *ngIf="canFreightTax" scope="col" class="col-1" style="width: 10%">
                            Tax - <input style="width: 80px" autocomplete="off" type="number" id="taxpercent" [(ngModel)]="this.dataService.order.taxpercent" name="taxpercent" />
                        </td>                        

                        <td scope="col" class="col-1" style="width: 10%">
                            {{this.dataService.order.tax | currency}}
                        </td>
                    </tr>      
                    <tr>
                        <td scope="col" class="col-1" style="width: 10%">
                            Total
                        </td>
                        <td scope="col" class="col-1" style="width: 10%">
                            {{this.dataService.order.total | currency}}
                        </td>
                    </tr>    
                    <tr>
                        <td scope="col" class="col-1" style="width: 10%">
                            Amount Paid
                        </td>
                        <td scope="col" class="col-1" style="width: 10%">
                            {{this.dataService.order.paid | currency}}
                        </td>
                    </tr>          
                    <tr>
                        <td scope="col" class="col-1" style="width: 10%">
                            P.O. Total
                        </td>
                        <td scope="col" class="col-1" style="width: 10%">
                            {{this.dataService.order.pototal | currency}}
                        </td>
                    </tr>                    
                </tbody>
            </table>
        </div>
    </div>

</div>
