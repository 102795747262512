import { Component } from '@angular/core';
import { FormControl } from "@angular/forms";
import { debounceTime, switchMap, startWith } from "rxjs/operators";
import { of } from "rxjs";
import { DataService, NotesService, AccountService } from '@app/_services';
import { map } from 'rxjs/operators';
@Component({
    selector: 'notes',
    templateUrl: 'notes.component.html',
  })
  export class NotesComponent {
    account = this.accountService.accountValue;
    search = new FormControl();
    notesControl = new FormControl();
    adding = false;
    note='';
    noteid=0;
    constructor(
        public dataService: DataService,
        private notesService: NotesService,
        private accountService: AccountService
    ) { 
        this.notesService.getNotes(this.dataService.order.id).pipe(
            map(notes => {
                    this.dataService.noteslist = notes;
            }),
        ).subscribe();
    }

    $search = this.search.valueChanges.pipe(
        startWith(null),
        debounceTime(200),
        switchMap((res: string) => {
            debugger;
            if (!res) return of(this.dataService.noteslist);
            res = res.toLowerCase();
            return of(
                this.dataService.noteslist.filter(x => (x.note.toLowerCase().indexOf(res) >= 0 || x.authorname.toLowerCase().indexOf(res) >= 0) )
            );
        })
    );
      selectionChange(option: any) {
        let value = this.notesControl.value || [];
        if (option.selected) value.push(option.value);
        else value = value.filter((x: any) => x != option.value);
        this.notesControl.setValue(value);
      }

      delay(ms: number)
      {
          return new Promise(resolve => setTimeout(resolve, ms));
      }

      close(obj) {

        this.dataService.dialogRef.close();
    }

      itemSelected(obj) {
        debugger;

    }

    addNote() {
        this.note = '';
        this.adding = true;
    }


    cancelNote() {
        this.adding = false;
    }

    async saveNote() {
        if (this.note === "") {
            alert("Note is required");
            return;
        }

        var noteObj = {
            note: this.note,
            author: this.account.user.id,
            order_id: this.dataService.order.id
        }

        this.notesService.addNote(noteObj).pipe(
            map( async note => {
          
            }),
        ).subscribe();

        await this.delay(500);
        this.adding = false;

        this.notesService.getNotes(this.dataService.order.id).pipe(
            map(notes => {
                    this.dataService.noteslist = notes;
            }),
        ).subscribe();    
    }


}