<div *ngIf="!adding && !editing" >
  <div class="row">
    <div class="col-8">
      <mat-form-field style="width:90%" autocomplete="off">
        <mat-label>Search</mat-label>
        <input matInput [formControl]="search" autocomplete="off" type="search">
      </mat-form-field>
    </div>
    <div class="col-3"><button class="btn btn-primary" (click)="addVendor()">Add New Vendor</button></div>
    <div class="col-1">
      <i class='fas fa-window-close' style='font-size:24px;color:gray; float: right;' (click)="close()"></i>
    </div>
  </div>
  <mat-dialog-content class="mat-typography">
    

    <div class="col-8"><h2 mat-dialog-title>Vendors</h2></div>

      <mat-selection-list #items [multiple]="false" (selectionChange)="selectionChange($event.option)">
        <div class="row">
          <div class="col">Vendor #</div>
          <div class="col">Name</div>
          <div class="col">City</div>
          <div class="col">State</div>
          <div class="col"></div>
        </div>
        <hr>
        <mat-list-option *ngFor="let vendor of $search|async" (click)="itemSelected(vendor)" [value]="vendor" [selected]="vendorsControl.value &&vendorsControl.value?.indexOf(item)>=0">
          <div class="row">
            <div class="col">{{vendor['vendor_nbr']}}</div>
            <div class="col">{{vendor['name']}}</div>
            <div class="col">{{vendor['city']}}</div>
            <div class="col">{{vendor['state']}}</div>
            <div class="col"><button class="btn btn-primary" (click)="editVendor(vendor)">Edit</button></div>
          </div>
        </mat-list-option>
      </mat-selection-list>

  </mat-dialog-content>
</div>

<!--  EDITING -->
<div *ngIf="editing">
  <div class="col-8"><h2 mat-dialog-title>Editing Vendor</h2></div>

  <hr>

  <mat-form-field style="width:60%" autocomplete="off">
    <mat-label>Vendor #</mat-label>
    <input matInput autocomplete="off" [(ngModel)]="vendor_nbr">
  </mat-form-field>

  <mat-form-field style="width:60%" autocomplete="off">
    <mat-label>Vendor Name</mat-label>
    <input matInput autocomplete="off" [(ngModel)]="vendor_name">
  </mat-form-field>

  <mat-form-field style="width:60%" autocomplete="off">
    <mat-label>Street</mat-label>
    <input matInput autocomplete="off"  [(ngModel)]="vendor_street">
  </mat-form-field>

  <mat-form-field style="width:60%" autocomplete="off">
    <mat-label>City</mat-label>
    <input matInput autocomplete="off"  [(ngModel)]="vendor_city">
  </mat-form-field>

  <mat-form-field style="width:60%" autocomplete="off">
    <mat-label>State</mat-label>
    <input matInput autocomplete="off" [(ngModel)]="vendor_state">
  </mat-form-field>

  <mat-form-field style="width:60%" autocomplete="off">
    <mat-label>Postal</mat-label>
    <input matInput autocomplete="off" [(ngModel)]="vendor_postal">
  </mat-form-field>

  <mat-form-field style="width:60%" autocomplete="off">
    <mat-label>Phone</mat-label>
    <input matInput autocomplete="off" [(ngModel)]="vendor_phone">
  </mat-form-field>

  <mat-form-field style="width:60%" autocomplete="off">
    <mat-label>Fax</mat-label>
    <input matInput autocomplete="off" [(ngModel)]="vendor_fax">
  </mat-form-field>

  <mat-form-field style="width:60%" autocomplete="off">
    <mat-label>Email</mat-label>
    <input matInput autocomplete="off" [(ngModel)]="vendor_email">
  </mat-form-field>

  <mat-form-field style="width:60%" autocomplete="off">
    <mat-label>Tax #</mat-label>
    <input matInput autocomplete="off" [(ngModel)]="vendor_taxnumber">
  </mat-form-field>

  <div class="row">
    <div class="col-1">
      <button class="btn btn-primary" (click)="updateVendor()">Save</button>
    </div>
    <div class="col-1">
      <button class="btn btn-danger" (click)="cancelVendor()">Cancel</button>
    </div>
  </div>


</div>

<!-- ADDING -->
<div *ngIf="adding">
  <div class="col-8"><h2 mat-dialog-title>Adding New Vendor</h2></div>

  <hr>

  <mat-form-field style="width:60%" autocomplete="off">
    <mat-label>Vendor #</mat-label>
    <input matInput autocomplete="off" [(ngModel)]="vendor_nbr">
  </mat-form-field>

  <mat-form-field style="width:60%" autocomplete="off">
    <mat-label>Vendor Name</mat-label>
    <input matInput autocomplete="off" [(ngModel)]="vendor_name">
  </mat-form-field>

  <mat-form-field style="width:60%" autocomplete="off">
    <mat-label>Street</mat-label>
    <input matInput autocomplete="off"  [(ngModel)]="vendor_street">
  </mat-form-field>

  <mat-form-field style="width:60%" autocomplete="off">
    <mat-label>City</mat-label>
    <input matInput autocomplete="off"  [(ngModel)]="vendor_city">
  </mat-form-field>

  <mat-form-field style="width:60%" autocomplete="off">
    <mat-label>State</mat-label>
    <input matInput autocomplete="off" [(ngModel)]="vendor_state">
  </mat-form-field>

  <mat-form-field style="width:60%" autocomplete="off">
    <mat-label>Postal</mat-label>
    <input matInput autocomplete="off" [(ngModel)]="vendor_postal">
  </mat-form-field>

  <mat-form-field style="width:60%" autocomplete="off">
    <mat-label>Phone</mat-label>
    <input matInput autocomplete="off" [(ngModel)]="vendor_phone">
  </mat-form-field>

  <mat-form-field style="width:60%" autocomplete="off">
    <mat-label>Fax</mat-label>
    <input matInput autocomplete="off" [(ngModel)]="vendor_fax">
  </mat-form-field>  

  <mat-form-field style="width:60%" autocomplete="off">
    <mat-label>Email</mat-label>
    <input matInput autocomplete="off" [(ngModel)]="vendor_email">
  </mat-form-field>

  <mat-form-field style="width:60%" autocomplete="off">
    <mat-label>Tax #</mat-label>
    <input matInput autocomplete="off" [(ngModel)]="vendor_taxnumber">
  </mat-form-field>


  <div class="row">
    <div class="col-1">
      <button class="btn btn-primary" (click)="saveVendor()">Save</button>
    </div>
    <div class="col-1">
      <button class="btn btn-danger" (click)="cancelVendor()">Cancel</button>
    </div>
  </div>

  


</div>
