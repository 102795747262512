import { Component, OnInit , Input} from '@angular/core';
import { OrdersService } from '../../_services/orders.service';
import { AccountService } from '@app/_services';
import { DataService } from '@app/_services';
import { Orders } from '../../_models';
import { QueryOptions } from '@app/_models/query-opts';
import { Observable, Subject } from 'rxjs';
import { takeUntil, map } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import moment, { Moment } from 'moment';

@Component({
    templateUrl: 'dashboard.component.html',
    providers: [OrdersService]
})


export class DashboardComponent implements OnInit {
    itemsperpage: number;
    currentpage: number;
    totalrecords: number;
    pageSize: number = 10;
    page: number = 1;
    totalPages: number;

    itemsperpage2: number;
    currentpage2: number;
    totalrecords2: number;
    pageSize2: number = 10;
    page2: number = 1;
    totalPages2: number;

    account = this.accountService.accountValue;
    orders: Orders;
    title: string;
    timestamp: string;
    datestamp: string;
    orders$: Observable<Orders>;
    viewtitle = "Purchase Orders";
    viewtitle2 = "Purchase Orders";
    canAdd: boolean;
    isPM: boolean;
    isFinal: boolean;
    isReceiver: boolean;


    queryOptions: QueryOptions = new QueryOptions();
    queryOptions2: QueryOptions = new QueryOptions();

    ranges: any = {
        Today: [moment(), moment()],
        Yesterday: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
        'Last 7 Days': [moment().subtract(6, 'days'), moment()],
        'Last 30 Days': [moment().subtract(29, 'days'), moment()],
        'This Month': [moment().startOf('month'), moment().endOf('month')],
        'Last Month': [
          moment()
            .subtract(1, 'month')
            .startOf('month'),
          moment()
            .subtract(1, 'month')
            .endOf('month')
        ],
        'Last 3 Month': [
          moment()
            .subtract(3, 'month')
            .startOf('month'),
          moment()
            .subtract(1, 'month')
            .endOf('month')
        ]
    };

    constructor(private accountService: AccountService,
            public dataService: DataService,
            private ordersService: OrdersService,
            private route: ActivatedRoute,
            private router: Router
        ) {
        this.queryOptions.page = 1;
        this.queryOptions.pageSize = 5;
        this.queryOptions.sortDirection = 'desc';

        this.queryOptions2.page = 1;
        this.queryOptions2.pageSize = 5;
        this.queryOptions2.sortDirection = 'desc';
        
     
    }

   

    ngOnInit(): void {
        this.itemsperpage = 5;
        this.currentpage = 1;

        this.itemsperpage2 = 5;
        this.currentpage2 = 1;

        localStorage.removeItem('purchaseorder'); 
        this.isPM = this.account.user.role === 'purchasingmanager';
        this.isFinal = this.account.user.role === 'approver';
        this.isReceiver = this.account.user.role === 'receiving';

        this.dataService.isFinal = this.account.user.role === 'approver';
        this.dataService.canComplete = this.account.user.role === 'approver' || this.account.user.role === 'purchasingmanager' || this.account.user.role === 'receiving';
        this.dataService.canAdd = this.account.user.role !== 'receiving';

        if (!this.dataService.startDate) {

            this.dataService.startDate = moment().subtract(6, 'days');
            this.dataService.endDate = moment();
       
            this.dataService.selectedDates = { startDate: this.dataService.startDate, endDate: this.dataService.endDate };
        }





        this.queryOptions.start = new Date(this.dataService.startDate);
        this.queryOptions.end = new Date(this.dataService.endDate);
        
        var term: string;
        var objValue: string;

        this.loadOrders();

        /*
        //this.companyLeads = this.LeadsService.getLeads(this.queryOptions, this.account.currentorg.org_id);
        document.getElementById('searchInput').addEventListener('input', (e) => {
            //console.log(`Input value: "${e.currentTarget.value}"`);
            console.log(`Input value: "${e.currentTarget['value']}"`);

            //if (e.currentTarget['value'] !== '') {
                this.dataService.companyLeads.results = Array.from(this.dataService.companyLeadsOrig);
                this.dataService.companyLeads.results = this.dataService.companyLeads.results.filter((obj)=>{
                    return Object.keys(obj).reduce((acc, curr)=>{
                        term = e.currentTarget['value'].toLowerCase();

                        if (curr === 'page_count' || curr === 'session_count') {
                            objValue = obj[curr].toString();
                            return acc || objValue.includes(term);
                        } else if (curr === 'isisp' || curr === 'company_id') {
                            return acc;
                        } else {
                            objValue = obj[curr].toLowerCase();
                            return acc || objValue.includes(term);
                        }

                    }, false);
                });
            //} else {
              //  this.refreshLeads('page_count');
            //}


       })
       */

    }




    ngOnDestroy(): void {        

    }

    pageSizeChange(event) {
        this.pageSize = event;
      }
    
    handlePageChange(event) {
    this.queryOptions.page = event;
    this.queryOptions.pageSize = this.pageSize;
    this.currentpage = event;
    // this.refreshLeads(this.queryOptions.sortOrder);  
    }

    pageSizeChange2(event) {
        this.pageSize2 = event;
      }
    
    handlePageChange2(event) {
    this.queryOptions2.page = event;
    this.queryOptions2.pageSize = this.pageSize2;
    this.currentpage2 = event;
    // this.refreshLeads(this.queryOptions.sortOrder);  
    }

    loadOrders() {
        this.queryOptions.sortOrder = 'requestdate';
        this.queryOptions.sortDirection = 'desc';
        this.queryOptions.pageSize = 1000000;

        this.queryOptions2.sortOrder = 'requestdate';
        this.queryOptions2.sortDirection = 'desc';
        this.queryOptions2.pageSize = 1000000;

        // is this a requestor?
        if (this.account.user.role === 'requestor') {
            this.canAdd = true;
            this.viewtitle = 'My Current Purchase Orders';
            this.ordersService.getMyActiveOrders(this.queryOptions, this.account.user.id).pipe(
                map(orders => {
                        this.dataService.orders = orders["orders"];
                        this.totalrecords = this.dataService.orders.length;
                }),
            ).subscribe();
            this.viewtitle2 = 'My Approved Purchase Orders';
            this.ordersService.getMyApprovedOrders(this.queryOptions, this.account.user.id).pipe(
                map(orders2 => {
                        this.dataService.orders2 = orders2["orders"];
                        this.totalrecords2= this.dataService.orders2.length;
                }),
            ).subscribe();            
        } else {
            this.canAdd = false;
            
            if (this.isReceiver) {
                this.viewtitle = 'Approved Purchased Orders';
                this.ordersService.getApprovedPurchaseOrders(this.queryOptions).pipe(
                    map(orders => {
                            this.dataService.orders = orders["orders"];
                            this.totalrecords = this.dataService.orders.length;
                    }),
                ).subscribe();

                this.viewtitle2 = 'Completed Purchase Orders';
                this.ordersService.getCompletedPurchaseOrders(this.queryOptions ).pipe(
                    map(orders => {
                            this.dataService.orders2 = orders["orders"];
                            this.totalrecords2 = this.dataService.orders.length;
                    }),
                ).subscribe();   
            } else if (this.isPM) {
                this.viewtitle = 'Purchase Orders Awaiting My Approval';
                this.ordersService.getMyOrdersForApproval(this.queryOptions).pipe(
                    map(orders => {
                            this.dataService.orders = orders["orders"];
                            this.totalrecords = this.dataService.orders.length;
                    }),
                ).subscribe();



                this.viewtitle2 = 'My Orders';
                this.ordersService.getMyOrders(this.queryOptions, this.account.user.id ).pipe(
                    map(orders => {
                            this.dataService.orders2 = orders["orders"];
                            this.totalrecords2 = this.dataService.orders.length;
                    }),
                ).subscribe();                
            } else {
                this.viewtitle = 'Purchase Orders Awaiting My Approval';
                this.ordersService.getMyOrdersForFinalApproval(this.queryOptions ).pipe(
                    map(orders => {
                            this.dataService.orders = orders["orders"];
                    }),
                ).subscribe();

                this.viewtitle2 = 'All Purchase Orders';
                this.ordersService.getAllPurchaseOrders(this.queryOptions ).pipe(
                //    this.ordersService.getMyPreviousFinalApprovals(this.queryOptions, this.account.user.id ).pipe(                    
                    map(orders => {
                            this.dataService.orders2 = orders["orders"];
                    }),
                ).subscribe();           

            }
        }
    
      
      }
      sortLeads(sortOrder) {
      }

      addOrder() {

        this.dataService.order = null;
        this.router.navigate(['/orders/order'], { relativeTo: this.route })
      }


      editOrder(order) {
        this.dataService.order = order;
        this.router.navigate(['/orders/order'], { relativeTo: this.route })
      }
/*
    sortLeads(sortOrder) {
        // is this a new sort order?
        if (this.queryOptions.sortOrder === sortOrder) {
            if (this.queryOptions.sortDirection === 'asc') {
                this.queryOptions.sortDirection = 'desc';
            } else {
                this.queryOptions.sortDirection = 'asc';
            }
        } else {
            this.queryOptions.sortDirection = 'desc';
        }
        this.queryOptions.sortOrder = sortOrder;

        $(`#company_employees_sort`).removeClass('btn-sort');
        $(`#company_revenue_sort`).removeClass('btn-sort');
        $(`#page_count_sort`).removeClass('btn-sort');
        $(`#session_count_sort`).removeClass('btn-sort');

        $(`#company_employees_sort`).removeClass('btn-sort-asc');
        $(`#company_revenue_sort`).removeClass('btn-sort-asc');
        $(`#page_count_sort`).removeClass('btn-sort-asc');
        $(`#session_count_sort`).removeClass('btn-sort-asc');

        $(`#company_employees_sort`).addClass('btn-hollow');
        $(`#company_revenue_sort`).addClass('btn-hollow');
        $(`#page_count_sort`).addClass('btn-hollow');
        $(`#session_count_sort`).addClass('btn-hollow');

        if (this.queryOptions.sortDirection === 'desc') {
            $(`#${sortOrder}_sort`).addClass('btn-sort');
            if (sortOrder==='company_employees' || sortOrder==='company_revenue') {
              this.dataService.companyLeads.results.sort((a, b) => (parseInt(a[sortOrder].split(" ")[0].replace(",","")) < parseInt(b[sortOrder].split(" ")[0].replace(",",""))) ? 1 : -1)
            } else {
              this.dataService.companyLeads.results.sort((a, b) => (a[sortOrder] < b[sortOrder]) ? 1 : -1)
            }
    
        } else {
            $(`#${sortOrder}_sort`).addClass('btn-sort-asc');
            if (sortOrder==='company_employees' || sortOrder==='company_revenue') {
              this.dataService.companyLeads.results.sort((a, b) => (parseInt(a[sortOrder].split(" ")[0].replace(",","")) > parseInt(b[sortOrder].split(" ")[0].replace(",",""))) ? 1 : -1)
            } else {
              this.dataService.companyLeads.results.sort((a, b) => (a[sortOrder] > b[sortOrder]) ? 1 : -1)
            }
        }
        $(`#${sortOrder}_sort`).removeClass('btn-hollow');
    


    }
*/


     
}