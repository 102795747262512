import { Component, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ShowOnDirtyErrorStateMatcher } from '@angular/material/core';
import { DataService, OrdersService, AccountService, NotificationService } from '@app/_services';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { takeUntil, map } from 'rxjs/operators';
import { Order } from '@app/_models';
import { ThisReceiver } from '@angular/compiler';
import { MatDialog } from '@angular/material/dialog';
import { PicklistComponent } from '@app/_components/picklist/picklist.component';
import { NotesComponent } from '@app/_components/picklist/notes.component';
import { VendorPicklistComponent } from '@app/_components/picklist/vendorpicklist.component';
import { Router, ActivatedRoute } from '@angular/router';
import {  ChangeDetectorRef } from '@angular/core';
import {MatCheckboxChange, MatCheckboxModule, MAT_CHECKBOX_DEFAULT_OPTIONS} from '@angular/material/checkbox';
import { environment } from '@environments/environment';
import moment, { Moment } from 'moment';

import {
    DialogLayoutDisplay,
    ConfirmBoxInitializer
  } from '@costlydeveloper/ngx-awesome-popup';
import { isObject } from 'highcharts';
import { now } from 'jquery';
//import { checkServerIdentity } from 'tls';

@Component({
  selector: 'app-company-info-form',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.scss']
})

export class OrderComponent implements OnInit {
  cdRef: ChangeDetectorRef;
  account = this.accountService.accountValue;
  loading: boolean = false;
  adding: boolean = false;
  submitted: boolean = false;
  showadd: boolean = false;
  orderForm: FormGroup;
  neworder: boolean;
  canDelete: boolean;
  canComplete: boolean;
  canSubmit: boolean;
  canSave: boolean;
  canUpdate: boolean;
  canSelectVendor: boolean;
  canApprove: boolean;
  canFreightTax: boolean;
  showFinal: boolean;
  showPM: boolean;
  isPM: boolean;
  isFinal: boolean;

  item: any = {
      job: '',
      description: '',
      duedate: '',
      unitprice: 0,
      uom: 'ea',
      quantity: 0,
      amount: 0

  };
  olditem;


  query3 = '';

  constructor(
   public dataService: DataService,
   public accountService: AccountService,
   private ordersService: OrdersService,
   private notificationService: NotificationService,
   public dialog: MatDialog,
   private route: ActivatedRoute,
   private router: Router,
   private cdr: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.showadd = false;
    this.isPM = this.account.user.role === 'purchasingmanager';
    this.isFinal = this.account.user.role === 'approver';




    debugger;

    
    if (localStorage.getItem('purchaseorder')) {
        this.loadOrder(localStorage.getItem('purchaseorder'));
    };

    // is there an order?
    if (this.dataService.order) {
        this.loadOrder(this.dataService.order.po_nbr);
        localStorage.setItem('purchaseorder', this.dataService.order.po_nbr);


    } else {
        this.canSave = true;
        this.canSubmit = true;
        this.canComplete = false;
        this.neworder = true;
        this.canSelectVendor = true;
        this.showPM = false;
        this.showFinal = false;
        this.canFreightTax = true;


        this.dataService.order = {
            po_nbr: 'not assigned',
            requestdate: Date.now(),
            vendorname: '',
            taxnumber: '',
            phone: '',
            status: 'draft',
            address: '',
            city: '',
            state: '',
            postal: '',
            fulladdress: '',
            approver1: null,
            approver2: null,
            requestorname:  this.accountService.accountValue.user.firstname + " " + this.accountService.accountValue.user.lastname,
            freight: 0,
            subtotal: 0,
            tax: 0,
            total: 0,
            paid: 0,
            pototal: 0,
            taxpercent: .00,
            ponotes: '',
            digitalinventorychecked: 0,
            physicalinventorychecked: 0,
            price: 0,
            whenneeded: null,
            whenplaced: null,
            expectdate: null,
            arrivaldate: null,
            bin: ''
        }
        this.dataService.setShipTo();
    }

    
   
   }

   loadOrder(poNbr) {
        this.neworder = false;

        // load the order
        this.ordersService.getOrder(poNbr).pipe(
            map(orderX => {
                    console.log(1);
                    console.log(orderX)
                    this.dataService.order = orderX.purchaseorder;
                    this.dataService.order.fulladdress = this.dataService.order.street + "\n" + this.dataService.order.city + ", " + this.dataService.order.state + " " + this.dataService.order.postal;

                    console.log(2);
                    console.log(this.dataService.order)

                    this.showPM = false;
                    this.showFinal = false;

                    if (this.dataService.order.approver1) {
                        this.showPM = true;
                        this.canFreightTax = true;
                    }
                    if (this.dataService.order.approver2) {
                        this.showFinal = true;
                        this.canFreightTax = true;
                    }

                    if (this.dataService.order.status==='draft') {
                        this.canSelectVendor = true;
                        this.canSave = true;
                        this.canSubmit = true;
                        this.canDelete = true;
                        this.canApprove = false;
                        this.canFreightTax = true;

                    } else {
                        // is this the requestor
                        if (this.accountService.accountValue.user.role === 'requestor') {
                            this.canSave = false;
                            this.canSubmit = false;
                            this.canSelectVendor = false;
                            this.canApprove = false;
                            this.canFreightTax = false;
                        } else {
                            if (this.accountService.accountValue.user.role === 'purchasingmanager') {
                                this.canSave = true;
                                this.canSubmit = false;
                                this.canSelectVendor = false;
                                this.canApprove = false;
                                if (this.dataService.order.status==='new') {
                                    this.canSelectVendor = true;
                                    this.canApprove = true;
                                    this.canFreightTax = true;
                                } 
                                if (this.dataService.order.status==='initial approval') {
                                    this.canSave = false;
                                    this.canFreightTax = false;
                                } 
                                if (this.dataService.order.status==='approved' || this.dataService.order.status==='completed') {
                                    this.canSave = false;
                                    this.canSubmit = false;
                                    this.canSelectVendor = false;
                                    this.canApprove = false;
                                    this.canFreightTax = false;
                                    this.canComplete = true;
                                }
                            } else {

                                this.canSave = true;
                                this.canSubmit = false;
                                this.canSelectVendor = true;
                                this.canApprove = true;
                                this.canFreightTax = true;

                                if (this.dataService.order.status==='approved' || this.dataService.order.status==='completed') {
                                    this.canSave = false;
                                    this.canSubmit = false;
                                    this.canSelectVendor = false;
                                    this.canApprove = true;
                                    this.canFreightTax = false;
                                    this.canComplete = true;
                                    this.canDelete = true;
                                }
                            }
                        }
            
                    }
                
            }),
        ).subscribe();
   }

   openDialog() {
    this.dataService.dialogRef = this.dialog.open(PicklistComponent, {
        height: '80%',
        width: '90%',
    });
    this.dataService.dialogRef.afterClosed().subscribe(result => {
      this.item.item = this.dataService.selecteditem;
      this.item.description = this.dataService.selecteditemdescription;
      this.item.item_id = this.dataService.selecteditemid;
    });
  }

  showNotes() {
    this.dataService.dialogRef = this.dialog.open(NotesComponent, {
        height: '90%',
        width: '90%',
    });
    this.dataService.dialogRef.afterClosed().subscribe(result => {
      this.item.item = this.dataService.selecteditem;
      this.item.description = this.dataService.selecteditemdescription;
      this.item.id = this.dataService.selecteditemid;
    });
  }

  openVendorDialog() {
    this.dataService.dialogRef = this.dialog.open(VendorPicklistComponent, {
        height: '80%',
        width: '90%',
    });
    this.dataService.dialogRef.afterClosed().subscribe(result => {
        console.log(result);
        this.dataService.order.vendorname = this.dataService.selectedVendor.name;
        this.dataService.order.street = this.dataService.selectedVendor.street;
        this.dataService.order.city = this.dataService.selectedVendor.city;
        this.dataService.order.state = this.dataService.selectedVendor.state;
        this.dataService.order.postal = this.dataService.selectedVendor.postal;
        this.dataService.order.vendor = this.dataService.selectedVendor.id;
        this.dataService.order.taxnumber = this.dataService.selectedVendor.taxnumber;
        this.dataService.order.phone = this.dataService.selectedVendor.phone;
        this.dataService.order.fax = this.dataService.selectedVendor.fax;

        this.dataService.order.fulladdress = this.dataService.order.street + "\n" + this.dataService.order.city + ", " + this.dataService.order.state + " " + this.dataService.order.postal
      //this.item.description = this.dataService.selecteditem;
      //this.item.notes = this.dataService.selecteditemnotes;
      //this.item.id = this.dataService.selecteditemid;
    });
  }

   sortLeads(obj) {

   }

   addItem() {

        this.item = {
            item: '',
            job: '',
            glcode: '',
            duedate: new Date().toISOString(),
            price: 0,
            uom: 'ea',
            qty: 0,
            total_price: 0,
            description: '',
            id: 0
        }
        this.adding = true;
        this.showadd = true;

        this.canSave = false;
        this.canSubmit = false;
   }
   cancelItem() {
    if (this.adding) {

    } else {

        this.item = {
            item: this.olditem.item,
            job: this.olditem.job,
            glcode: this.olditem.glcode,
            duedate: this.olditem.duedate,
            price: this.olditem.price,
            uom: this.olditem.uom,
            qty: this.olditem.qty,
            total_price: this.olditem.total_price,
            description: this.olditem.description,
            id: this.olditem.id
        }
    }

    this.adding = false;
    this.showadd = false;

    this.canSave = true;
    this.canSubmit = true;

    this.cdr.detectChanges();

   }
   saveItem() {
        debugger;
        if (!this.item.qty) {
            alert("Please enter a quantity");
            return;
        }
        if (!this.item.price) {
            alert("Please enter a unit price");
            return;
        }        
        if (isNaN(this.item.price)) {
            alert("Please enter a valid unit price");
            return;
        }        


        if (this.item.item==="") {
            alert("Please enter an item");
            return;
        }

        this.item.total_price = this.item.qty * this.item.price;
        if (this.item.uom==='m') {
            this.item.total_price = this.item.total_price/1000;
        }
        this.calcTotals();

        this.showadd = false;
        this.canSave = true;
        this.canSubmit = true;

        if (!this.dataService.order.items) {
            this.dataService.order.items = [];
        }

        /*
        if (this.item.duedate.toISOString) {
            this.item.duedate = this.item.duedate.toISOString().substr(0,10) + ' 00:00:00';
        } else {
            this.item.duedate = this.item.duedate.substr(0,10) + ' 00:00:00';
        }
        */

        // is this a new record or an update?
        if (this.adding) {


            

            this.dataService.order.items.push(
                {
                    job: this.item.job,
                    item: this.item.item,
                    description: this.item.description,
                    duedate: this.item.duedate,
                    glcode: this.item.glcode,
                    price: this.item.price,
                    uom: this.item.uom,
                    qty: this.item.qty,
                    total_price: this.item.total_price,
                    source: 'new',
                    id: this.item.item_id
                }
            )

        } else {
           
            // should only be updated if this order already exists and the item is not already a new one

            if (this.dataService.order.id && this.item.source !== 'new') {
                this.item.source='updated';
            }
        }


        this.calcTotals();

        this.adding = false;

   }   

   removeItem(item) {

        const confirmBox = new ConfirmBoxInitializer();
        confirmBox.setTitle('Confirm');
        confirmBox.setMessage('Are you sure you want to remove this item?');
        confirmBox.setButtonLabels('YES', 'NO');

        // Choose layout color type
        confirmBox.setConfig({
            layoutType: DialogLayoutDisplay.DANGER, // SUCCESS | INFO | NONE | DANGER | WARNING
        });

        // Simply open the popup and listen which button is clicked
        confirmBox.openConfirmBox$().subscribe(resp => {
            // IConfirmBoxPublicResponse
            console.log('Clicked button response: ', resp);

            if (resp.clickedButtonID === "yes") {
                item.source='deleted';
            
                this.calcTotals();        
            }
        });
   }



   editItem(item) {
       this.showadd = true;
       this.adding = false;
       //this.dataService.item = item;
       
        debugger;

       this.olditem = {
            item: item.item,
            job: item.job,
            glcode: item.glcode,
            duedate: item.duedate,
            price: item.price,
            uom: item.uom,
            qty: item.qty,
            total_price: item.total_price,
            description: item.description,
            id: item.id
        }

       this.item = item;
       this.canSave = false;
       this.canSubmit = false;

   }

  handleStaticResultSelected (result) {
    this.item.description = result.description;
    this.item.notes = result.notes;
    this.item.id = result.id;
  }

  async submitPO() {
    if (this.dataService.order.freight === null) {
        this.dataService.order.freight=0;
    }
    if (this.dataService.order.taxpercent === null) {
        this.dataService.order.taxpercent=0;
    }
 
    const confirmBox = new ConfirmBoxInitializer();
    confirmBox.setTitle('Confirm');
    confirmBox.setMessage('Are you sure you want to submit this purchase order?');
    confirmBox.setButtonLabels('YES', 'NO');

    // Choose layout color type
    confirmBox.setConfig({
        layoutType: DialogLayoutDisplay.SUCCESS, // SUCCESS | INFO | NONE | DANGER | WARNING
    });

    // Simply open the popup and listen which button is clicked
    confirmBox.openConfirmBox$().subscribe(async resp => {
        // IConfirmBoxPublicResponse
        console.log('Clicked button response: ', resp);

        if (resp.clickedButtonID === "yes") {

            var orderObj; 

            this.calcTotals();

            // does it already exist?
            if (this.dataService.order.id) {
                orderObj = {
                    "status": "new",
                    "requestor": this.accountService.accountValue.user.id,
                    "vendor": this.dataService.order.vendor,
                    "subtotal": this.dataService.order.subtotal,
                    "total": this.dataService.order.total,
                    "pototal": this.dataService.order.pototal,
                    "freight": this.dataService.order.freight,
                    "tax": this.dataService.order.tax,
                    "taxpercent": this.dataService.order.taxpercent,
                    "paid": this.dataService.order.paid,
                    "ponotes": this.dataService.order.ponotes,
                    "fob": this.dataService.order.fob,
                    "terms": this.dataService.order.terms,
                    "shipto": this.dataService.order.shipto,
                    "shiptoaddress": this.dataService.order.shiptoaddress,
                    "shipvia": this.dataService.order.shipvia,
                    "shiptophone": this.dataService.order.shiptophone,
                    "shiptofax": this.dataService.order.shiptofax,
                    "emailed": this.dataService.order.emailed,
                    "digitalinventorychecked": this.dataService.order.digitalinventorychecked,
                    "physicalinventorychecked": this.dataService.order.physicalinventorychecked,
                    "price": this.dataService.order.price,
                    "whenneeded": this.dataService.order.whenneeded,
                    "whenplaced": this.dataService.order.whenplaced,
                    "expecteddate": this.dataService.order.expecteddate,
                    "arrivaldate": this.dataService.order.arrivaldate,
                    "bin": this.dataService.order.bin
                    //"approver1":this.dataService.approver1,
                    //"approver2":this.dataService.approver2
                };

                this.ordersService.updateOrder(this.dataService.order.po_nbr, orderObj).pipe(
                    map( async newOrder => {
                        console.log(newOrder);

                        this.updatePOitems();
                        await this.delay(500);
                        //this.loadOrder(this.dataService.order.po_nbr);
                        this.dataService.order = null;
                        this.router.navigate(['/dashboard'], { relativeTo: this.route });    
                    }),
                ).subscribe();

               
            } else {

                orderObj = {
                    "status": "new",
                    "requestor": this.accountService.accountValue.user.id,
                    "vendor": this.dataService.order.vendor,
                    "subtotal": this.dataService.order.subtotal,
                    "total": this.dataService.order.total,
                    "pototal": this.dataService.order.pototal,
                    "freight": this.dataService.order.freight,
                    "tax": this.dataService.order.tax,
                    "taxpercent": this.dataService.order.taxpercent,
                    "paid": this.dataService.order.paid,
                    "ponotes": this.dataService.order.ponotes,
                    "fob": this.dataService.order.fob,
                    "terms": this.dataService.order.terms,
                    "shipto": this.dataService.order.shipto,
                    "shiptoaddress": this.dataService.order.shiptoaddress,
                    "shipvia": this.dataService.order.shipvia,
                    "shiptophone": this.dataService.order.shiptophone,
                    "shiptofax": this.dataService.order.shiptofax,
                    "emailed": this.dataService.order.emailed,
                    "digitalinventorychecked": this.dataService.order.digitalinventorychecked,
                    "physicalinventorychecked": this.dataService.order.physicalinventorychecked,
                    "price": this.dataService.order.price,
                    "whenneeded": this.dataService.order.whenneeded,
                    "whenplaced": this.dataService.order.whenplaced,
                    "expecteddate": this.dataService.order.expecteddate,
                    "arrivaldate": this.dataService.order.arrivaldate,
                    "bin": this.dataService.order.bin
                };

                this.ordersService.addOrder(orderObj).pipe(
                    map( async neworder => {
                        console.log(neworder);
                        
                        this.dataService.order.id = neworder.id;
                        this.updatePOitems();

                        this.notificationService.getPMs().pipe(

                            map( async pms => {

                                pms = pms.map(x => x.email).join(",")
                                console.log(pms);
                                var mailObj = {
                                    "to": pms,
                                    "subject": "A new purchase order was submitted",
                                    "text": `${this.account.user.firstname} ${this.account.user.lastname} has submitted a new purchase order - ${neworder.order}`,
                                    "html": `${this.account.user.firstname} ${this.account.user.lastname} has submitted a new purchase order - ${neworder.order}<br><br><a href="${environment.homepage}">Purchase Order Site</a>`
                                };
                            
                                
                                this.notificationService.sendNotification(mailObj).pipe(
                                    map( async notification => {
                                        console.log(notification);
                                    })
                                ).subscribe();
        
                            })
                        ).subscribe()

                        this.dataService.order = null;
                        this.router.navigate(['/dashboard'], { relativeTo: this.route });    

                        //await this.loadOrder(neworder.order);

                    }),
                ).subscribe();


            }


           
        }
        
    });




    
 
  }

 
  async submitAndApprovePO()    {
    debugger;
    if (!this.dataService.order.vendor ) {
        alert("Please choose a vendor");
        return;
    }

    if (this.dataService.order.freight === null) {
        this.dataService.order.freight=0;
    }
    if (this.dataService.order.taxpercent === null) {
        this.dataService.order.taxpercent=0;
    }

    const confirmBox = new ConfirmBoxInitializer();
    confirmBox.setTitle('Confirm');
    confirmBox.setMessage('Are you sure you want to submit and approve this purchase order?');
    confirmBox.setButtonLabels('YES', 'NO');

    // Choose layout color type
    confirmBox.setConfig({
        layoutType: DialogLayoutDisplay.SUCCESS, // SUCCESS | INFO | NONE | DANGER | WARNING
    });

    // Simply open the popup and listen which button is clicked
    confirmBox.openConfirmBox$().subscribe(async resp => {
        // IConfirmBoxPublicResponse
        console.log('Clicked button response: ', resp);

        if (resp.clickedButtonID === "yes") {

            this.calcTotals();

            var orderObj; 
            var m = moment().format("YYYY-MM-DD HH:mm:ss");
            // does it already exist?
            if (this.dataService.order.id) {
                orderObj = {
                    "status": "initial approval",
                    "approver1": this.account.user.id,
                    "approver1date": m,
                    "requestor": this.accountService.accountValue.user.id,
                    "vendor": this.dataService.order.vendor,
                    "subtotal": this.dataService.order.subtotal,
                    "total": this.dataService.order.total,
                    "pototal": this.dataService.order.pototal,
                    "freight": this.dataService.order.freight,
                    "tax": this.dataService.order.tax,
                    "taxpercent": this.dataService.order.taxpercent,
                    "paid": this.dataService.order.paid,
                    "ponotes": this.dataService.order.ponotes,
                    "fob": this.dataService.order.fob,
                    "terms": this.dataService.order.terms,
                    "shipto": this.dataService.order.shipto,
                    "shiptoaddress": this.dataService.order.shiptoaddress,
                    "shipvia": this.dataService.order.shipvia,
                    "shiptophone": this.dataService.order.shiptophone,
                    "shiptofax": this.dataService.order.shiptofax,
                    "emailed": this.dataService.order.emailed,
                    "digitalinventorychecked": this.dataService.order.digitalinventorychecked,
                    "physicalinventorychecked": this.dataService.order.physicalinventorychecked,
                    "price": this.dataService.order.price,
                    "whenneeded": this.dataService.order.whenneeded,
                    "whenplaced": this.dataService.order.whenplaced,
                    "expecteddate": this.dataService.order.expecteddate,
                    "arrivaldate": this.dataService.order.arrivaldate,
                    "bin": this.dataService.order.bin
                    //"approver1":this.dataService.approver1,
                    //"approver2":this.dataService.approver2
                };

                this.ordersService.updateOrder(this.dataService.order.po_nbr, orderObj).pipe(
                    map( async newOrder => {
                        console.log(newOrder);

                        this.updatePOitems();
                        await this.delay(500);
                        //this.loadOrder(this.dataService.order.po_nbr);

                        /*
                        this.notificationService.getFinals().pipe(

                            map( async finals => {
                                finals = finals.map(x => x.email).join(",")
                                console.log(finals);
                                var mailObj = {
                                    "to": finals,
                                    "subject": "A purchase has been approved and is awaiting your final approval",
                                    "text": `Purchase order - ${this.dataService.order.po_nbr} has been approved by ${this.account.user.firstname} ${this.account.user.lastname}`,
                                    "html": `Purchase order - ${this.dataService.order.po_nbr} has been approved by ${this.account.user.firstname} ${this.account.user.lastname}<br><br><a href="${environment.homepage}">Purchase Order Site</a>`
                                };
                            
                                
                                this.notificationService.sendNotification(mailObj).pipe(
                                    map( async notification => {
                                        console.log(notification);
                                    })
                                ).subscribe();
        
                            })
                        ).subscribe()
                        */


                        this.dataService.order = null;
                        this.router.navigate(['/dashboard'], { relativeTo: this.route });    
                    }),
                ).subscribe();

               
            } else {
                orderObj = {
                    "status": "initial approval",
                    "approver1": this.account.user.id,
                    "approver1date": m,
                    "requestor": this.accountService.accountValue.user.id,
                    "vendor": this.dataService.order.vendor,
                    "subtotal": this.dataService.order.subtotal,
                    "total": this.dataService.order.total,
                    "pototal": this.dataService.order.pototal,
                    "freight": this.dataService.order.freight,
                    "tax": this.dataService.order.tax,
                    "taxpercent": this.dataService.order.taxpercent,
                    "paid": this.dataService.order.paid,
                    "ponotes": this.dataService.order.ponotes,
                    "fob": this.dataService.order.fob,
                    "terms": this.dataService.order.terms,
                    "shipto": this.dataService.order.shipto,
                    "shiptoaddress": this.dataService.order.shiptoaddress,
                    "shipvia": this.dataService.order.shipvia,
                    "shiptophone": this.dataService.order.shiptophone,
                    "shiptofax": this.dataService.order.shiptofax,
                    "emailed": this.dataService.order.emailed,
                    "digitalinventorychecked": this.dataService.order.digitalinventorychecked,
                    "physicalinventorychecked": this.dataService.order.physicalinventorychecked,
                    "price": this.dataService.order.price,
                    "whenneeded": this.dataService.order.whenneeded,
                    "whenplaced": this.dataService.order.whenplaced,
                    "expecteddate": this.dataService.order.expecteddate,
                    "arrivaldate": this.dataService.order.arrivaldate,
                    "bin": this.dataService.order.bin
                    //"approver1":this.dataService.approver1,
                    //"approver2":this.dataService.approver2,
                    //"vendor": this.dataService.order.vendorid
                };

                this.ordersService.addOrder(orderObj).pipe(
                    map( async neworder => {
                        console.log(neworder);
                        
                        this.dataService.order.id = neworder.id;
                        this.updatePOitems();

                        /*
                        this.notificationService.getFinals().pipe(

                            map( async finals => {
                                finals = finals.map(x => x.email).join(",")
                                console.log(finals);
                                var mailObj = {
                                    "to": finals,
                                    "subject": "A purchase has been approved and is awaiting your final approval",
                                    "text": `Purchase order - ${neworder.order} has been approved by ${this.account.user.firstname} ${this.account.user.lastname}`,
                                    "html": `Purchase order - ${neworder.order} has been approved by ${this.account.user.firstname} ${this.account.user.lastname}<br><br><a href="${environment.homepage}">Purchase Order Site</a>`
                                };
                            
                                
                                this.notificationService.sendNotification(mailObj).pipe(
                                    map( async notification => {
                                        console.log(notification);
                                    })
                                ).subscribe();
        
                            })
                        ).subscribe()
                        */

                        this.dataService.order = null;
                        this.router.navigate(['/dashboard'], { relativeTo: this.route });    

                        //await this.loadOrder(neworder.order);

                    }),
                ).subscribe();
            }
        }
    });
  }


  async submitAndApproveFinalPO() {
    if (this.dataService.order.freight === null) {
        this.dataService.order.freight=0;
    }
    if (this.dataService.order.taxpercent === null) {
        this.dataService.order.taxpercent=0;
    }
    if (this.dataService.order.vendor) {
        alert("Please choose a vendor");
        return;
    }
 
    const confirmBox = new ConfirmBoxInitializer();
    confirmBox.setTitle('Confirm');
    confirmBox.setMessage('Are you sure you want to submit and provide final approval for this purchase order?');
    confirmBox.setButtonLabels('YES', 'NO');

    // Choose layout color type
    confirmBox.setConfig({
        layoutType: DialogLayoutDisplay.SUCCESS, // SUCCESS | INFO | NONE | DANGER | WARNING
    });

    // Simply open the popup and listen which button is clicked
    confirmBox.openConfirmBox$().subscribe(async resp => {
        // IConfirmBoxPublicResponse
        console.log('Clicked button response: ', resp);

        if (resp.clickedButtonID === "yes") {

            this.calcTotals();

            var orderObj; 
            var m = moment().format("YYYY-MM-DD HH:mm:ss");
            // does it already exist?
            if (this.dataService.order.id) {
                orderObj = {
                    "status": "approved",
                    "approver1": this.account.user.id,
                    "approver1date": m,
                    "approver2": this.account.user.id,
                    "approver2date": m,                    
                    "requestor": this.accountService.accountValue.user.id,
                    "vendor": this.dataService.order.vendor,
                    "subtotal": this.dataService.order.subtotal,
                    "total": this.dataService.order.total,
                    "pototal": this.dataService.order.pototal,
                    "freight": this.dataService.order.freight,
                    "tax": this.dataService.order.tax,
                    "taxpercent": this.dataService.order.taxpercent,
                    "paid": this.dataService.order.paid,
                    "ponotes": this.dataService.order.ponotes,
                    "fob": this.dataService.order.fob,
                    "terms": this.dataService.order.terms,
                    "shipto": this.dataService.order.shipto,
                    "shiptoaddress": this.dataService.order.shiptoaddress,
                    "shipvia": this.dataService.order.shipvia,
                    "shiptophone": this.dataService.order.shiptophone,
                    "shiptofax": this.dataService.order.shiptofax,
                    "emailed": this.dataService.order.emailed,
                    "digitalinventorychecked": this.dataService.order.digitalinventorychecked,
                    "physicalinventorychecked": this.dataService.order.physicalinventorychecked,
                    "price": this.dataService.order.price,
                    "whenneeded": this.dataService.order.whenneeded,
                    "whenplaced": this.dataService.order.whenplaced,
                    "expecteddate": this.dataService.order.expecteddate,
                    "arrivaldate": this.dataService.order.arrivaldate,
                    "bin": this.dataService.order.bin
                    //"approver1":this.dataService.approver1,
                    //"approver2":this.dataService.approver2
                };

                this.ordersService.updateOrder(this.dataService.order.po_nbr, orderObj).pipe(
                    map( async newOrder => {
                        console.log(newOrder);

                        this.updatePOitems();
                        await this.delay(500);
                        //this.loadOrder(this.dataService.order.po_nbr);

                    


                        this.dataService.order = null;
                        this.router.navigate(['/dashboard'], { relativeTo: this.route });    
                    }),
                ).subscribe();

               
            } else {
                orderObj = {
                    "status": "approved",
                    "approver1": this.account.user.id,
                    "approver1date": m,
                    "approver2": this.account.user.id,
                    "approver2date": m,
                    "requestor": this.accountService.accountValue.user.id,
                    "vendor": this.dataService.order.vendor,
                    "subtotal": this.dataService.order.subtotal,
                    "total": this.dataService.order.total,
                    "pototal": this.dataService.order.pototal,
                    "freight": this.dataService.order.freight,
                    "tax": this.dataService.order.tax,
                    "taxpercent": this.dataService.order.taxpercent,
                    "paid": this.dataService.order.paid,
                    "ponotes": this.dataService.order.ponotes,
                    "fob": this.dataService.order.fob,
                    "terms": this.dataService.order.terms,
                    "shipto": this.dataService.order.shipto,
                    "shiptoaddress": this.dataService.order.shiptoaddress,
                    "shipvia": this.dataService.order.shipvia,
                    "shiptophone": this.dataService.order.shiptophone,
                    "shiptofax": this.dataService.order.shiptofax,
                    "emailed": this.dataService.order.emailed,
                    "digitalinventorychecked": this.dataService.order.digitalinventorychecked,
                    "physicalinventorychecked": this.dataService.order.physicalinventorychecked,
                    "price": this.dataService.order.price,
                    "whenneeded": this.dataService.order.whenneeded,
                    "whenplaced": this.dataService.order.whenplaced,
                    "expecteddate": this.dataService.order.expecteddate,
                    "arrivaldate": this.dataService.order.arrivaldate,
                    "bin": this.dataService.order.bin
                    //"approver1":this.dataService.approver1,
                    //"approver2":this.dataService.approver2,
                    //"vendor": this.dataService.order.vendorid
                };

                this.ordersService.addOrder(orderObj).pipe(
                    map( async neworder => {
                        console.log(neworder);
                        
                        this.dataService.order.id = neworder.id;
                        this.updatePOitems();

                        

                        this.dataService.order = null;
                        this.router.navigate(['/dashboard'], { relativeTo: this.route });    

                        //await this.loadOrder(neworder.order);

                    }),
                ).subscribe();


            }


           
        }
        
    });




    
 
  }

  async savePO() {

    
    if (this.dataService.order.freight === null) {
        this.dataService.order.freight=0;
    }
    if (this.dataService.order.taxpercent === null) {
        this.dataService.order.taxpercent=0;
    }
      // saving as draft
    console.log(this.dataService.order);
    var orderObj;

    debugger;

    this.calcTotals();

    // does it already exist?
    if (this.dataService.order.id) {
        if (this.dataService.order.vendor) {
            orderObj = {
                "vendor": this.dataService.order.vendor,
                "subtotal": this.dataService.order.subtotal,
                "total": this.dataService.order.total,
                "pototal": this.dataService.order.pototal,
                "freight": this.dataService.order.freight,
                "tax": this.dataService.order.tax,
                "taxpercent": this.dataService.order.taxpercent,
                "paid": this.dataService.order.paid,
                "ponotes": this.dataService.order.ponotes,
                "fob": this.dataService.order.fob,
                "terms": this.dataService.order.terms,
                "shipto": this.dataService.order.shipto,
                "shiptoaddress": this.dataService.order.shiptoaddress,
                "shipvia": this.dataService.order.shipvia,
                "shiptophone": this.dataService.order.shiptophone,
                "shiptofax": this.dataService.order.shiptofax,
                "emailed": this.dataService.order.emailed,
                "digitalinventorychecked": this.dataService.order.digitalinventorychecked,
                "physicalinventorychecked": this.dataService.order.physicalinventorychecked,
                "price": this.dataService.order.price,
                "whenneeded": this.dataService.order.whenneeded,
                "whenplaced": this.dataService.order.whenplaced,
                "expecteddate": this.dataService.order.expecteddate,
                "arrivaldate": this.dataService.order.arrivaldate,
                "bin": this.dataService.order.bin
            };
        } else {
            orderObj = {
                "subtotal": this.dataService.order.subtotal,
                "total": this.dataService.order.total,
                "pototal": this.dataService.order.pototal,
                "freight": this.dataService.order.freight,
                "tax": this.dataService.order.tax,
                "taxpercent": this.dataService.order.taxpercent,
                "paid": this.dataService.order.paid,
                "ponotes": this.dataService.order.ponotes,
                "fob": this.dataService.order.fob,
                "terms": this.dataService.order.terms,
                "shipto": this.dataService.order.shipto,
                "shiptoaddress": this.dataService.order.shiptoaddress,
                "shipvia": this.dataService.order.shipvia,
                "shiptophone": this.dataService.order.shiptophone,
                "shiptofax": this.dataService.order.shiptofax,
                "emailed": this.dataService.order.emailed,
                "digitalinventorychecked": this.dataService.order.digitalinventorychecked,
                "physicalinventorychecked": this.dataService.order.physicalinventorychecked,
                "price": this.dataService.order.price,
                "whenneeded": this.dataService.order.whenneeded,
                "whenplaced": this.dataService.order.whenplaced,
                "expecteddate": this.dataService.order.expecteddate,
                "arrivaldate": this.dataService.order.arrivaldate,
                "bin": this.dataService.order.bin

            };
        }

        this.ordersService.updateOrder(this.dataService.order.po_nbr, orderObj).pipe(
            map( async order => {
                console.log(order);
                //await this.loadOrder(order.order);
                //await this.delay(500);

            }),
        ).subscribe();
        


        this.updatePOitems();
        await this.delay(500);
        this.loadOrder(this.dataService.order.po_nbr);
    } else {
        orderObj = {
            "status": "draft",
            "requestor": this.accountService.accountValue.user.id,
            "approver1": null,
            "approver2": null,
            "vendor": this.dataService.order.vendor,
            "subtotal": this.dataService.order.subtotal,
            "total": this.dataService.order.total,
            "pototal": this.dataService.order.pototal,
            "freight": this.dataService.order.freight,
            "tax": this.dataService.order.tax,
            "taxpercent": this.dataService.order.taxpercent,
            "paid": this.dataService.order.paid,
            "ponotes": this.dataService.order.ponotes,
            "fob": this.dataService.order.fob,
            "terms": this.dataService.order.terms,
            "shipto": this.dataService.order.shipto,
            "shiptoaddress": this.dataService.order.shiptoaddress,
            "shipvia": this.dataService.order.shipvia,
            "shiptophone": this.dataService.order.shiptophone,
            "shiptofax": this.dataService.order.shiptofax,
            "emailed": this.dataService.order.emailed,
            "digitalinventorychecked": this.dataService.order.digitalinventorychecked,
            "physicalinventorychecked": this.dataService.order.physicalinventorychecked,
            "price": this.dataService.order.price,
            "whenneeded": this.dataService.order.whenneeded,
            "whenplaced": this.dataService.order.whenplaced,
            "expecteddate": this.dataService.order.expecteddate,
            "arrivaldate": this.dataService.order.arrivaldate,
             "bin": this.dataService.order.bin
        };

        var ds = this.dataService;

        this.ordersService.addOrder(orderObj).pipe(
            map( async order => {
                console.log(order);
                await this.delay(500);
                ds.order.id = order.id;
                this.updatePOitems();
                await this.delay(500);
                await this.loadOrder(order.order);



            }),
        ).subscribe();
    }

  }

  async approvePO() {

    var orderObj; 
    if (this.dataService.order.freight === null) {
        this.dataService.order.freight=0;
    }
    if (this.dataService.order.taxpercent === null) {
        this.dataService.order.taxpercent=0;
    }
    if (!this.dataService.order.vendor) {
        alert("Please choose a vendor");
        return;
    }


    const confirmBox = new ConfirmBoxInitializer();
    confirmBox.setTitle('Confirm');
    confirmBox.setMessage('Are you sure you want to approve this purchase order?');
    confirmBox.setButtonLabels('YES', 'NO');

    // Choose layout color type
    confirmBox.setConfig({
        layoutType: DialogLayoutDisplay.SUCCESS, // SUCCESS | INFO | NONE | DANGER | WARNING
    });

    // Simply open the popup and listen which button is clicked
    confirmBox.openConfirmBox$().subscribe(async resp => {
        // IConfirmBoxPublicResponse
        console.log('Clicked button response: ', resp);

        this.calcTotals();

        if (resp.clickedButtonID === "yes") {
            var m = moment().format("YYYY-MM-DD HH:mm:ss");
            orderObj = {
                "status": "initial approval",
                "approver1": this.account.user.id,
                "approver1date":m,
                "vendor": this.dataService.order.vendor,
                "subtotal": this.dataService.order.subtotal,
                "total": this.dataService.order.total,
                "pototal": this.dataService.order.pototal,
                "freight": this.dataService.order.freight,
                "tax": this.dataService.order.tax,
                "taxpercent": this.dataService.order.taxpercent,
                "paid": this.dataService.order.paid,
                "ponotes": this.dataService.order.ponotes,
                "fob": this.dataService.order.fob,
                "terms": this.dataService.order.terms,
                "shipto": this.dataService.order.shipto,
                "shiptoaddress": this.dataService.order.shiptoaddress,
                "shipvia": this.dataService.order.shipvia,
                "shiptophone": this.dataService.order.shiptophone,
                "shiptofax": this.dataService.order.shiptofax,
                "emailed": this.dataService.order.emailed,
                "digitalinventorychecked": this.dataService.order.digitalinventorychecked,
                "physicalinventorychecked": this.dataService.order.physicalinventorychecked,
                "price": this.dataService.order.price,
                "whenneeded": this.dataService.order.whenneeded,
                "whenplaced": this.dataService.order.whenplaced,
                "expecteddate": this.dataService.order.expecteddate,
                "arrivaldate": this.dataService.order.arrivaldate,
                "bin": this.dataService.order.bin
            };

            this.ordersService.updateOrder(this.dataService.order.po_nbr, orderObj).pipe(
                map( async order => {
                    console.log(order);
                    /*
                    this.notificationService.getFinals().pipe(

                        map( async finals => {
                            finals = finals.map(x => x.email).join(",")
                            console.log(finals);
                            var mailObj = {
                                "to": finals,
                                "subject": "A purchase has been approved and is awaiting your final approval",
                                "text": `Purchase order - ${this.dataService.order.po_nbr} has been approved by ${this.account.user.firstname} ${this.account.user.lastname}`,
                                "html": `Purchase order - ${this.dataService.order.po_nbr} has been approved by ${this.account.user.firstname} ${this.account.user.lastname}<br><br><a href="${environment.homepage}">Purchase Order Site</a>`
                            };
                        
                            
                            this.notificationService.sendNotification(mailObj).pipe(
                                map( async notification => {
                                    console.log(notification);
                                })
                            ).subscribe();
    
                        })
                    ).subscribe()
                    */



                }),
            ).subscribe();

            this.updatePOitems();
            await this.delay(500);
            this.router.navigate(['/dashboard'], { relativeTo: this.route });
        }
    })
  }

  async approveFinalPO() {

    if (this.dataService.order.freight === null) {
        this.dataService.order.freight=0;
    }
    if (this.dataService.order.taxpercent === null) {
        this.dataService.order.taxpercent=0;
    }
    const confirmBox = new ConfirmBoxInitializer();
    confirmBox.setTitle('Confirm');
    confirmBox.setMessage('Are you sure you want to approve this purchase order?');
    confirmBox.setButtonLabels('YES', 'NO');

    // Choose layout color type
    confirmBox.setConfig({
        layoutType: DialogLayoutDisplay.SUCCESS, // SUCCESS | INFO | NONE | DANGER | WARNING
    });

    // Simply open the popup and listen which button is clicked
    confirmBox.openConfirmBox$().subscribe(async resp => {
        // IConfirmBoxPublicResponse
        console.log('Clicked button response: ', resp);

        if (resp.clickedButtonID === "yes") {

            this.calcTotals();

            var orderObj; 
            var m = moment().format("YYYY-MM-DD HH:mm:ss");

            orderObj = {
                "status": "approved",
                "approver2": this.account.user.id,
                "approver2date": m,
                "total": this.dataService.order.total,
                "pototal": this.dataService.order.pototal,
                "freight": this.dataService.order.freight,
                "tax": this.dataService.order.tax,
                "taxpercent": this.dataService.order.taxpercent,
                "paid": this.dataService.order.paid,
                "ponotes": this.dataService.order.ponotes,
                "fob": this.dataService.order.fob,
                "terms": this.dataService.order.terms,
                "shipto": this.dataService.order.shipto,
                "shiptoaddress": this.dataService.order.shiptoaddress,
                "shipvia": this.dataService.order.shipvia,
                "shiptophone": this.dataService.order.shiptophone,
                "shiptofax": this.dataService.order.shiptofax,
                "emailed": this.dataService.order.emailed,
                "digitalinventorychecked": this.dataService.order.digitalinventorychecked,
                "physicalinventorychecked": this.dataService.order.physicalinventorychecked,
                "price": this.dataService.order.price,
                "whenneeded": this.dataService.order.whenneeded,
                "whenplaced": this.dataService.order.whenplaced,
                "expecteddate": this.dataService.order.expecteddate,
                "arrivaldate": this.dataService.order.arrivaldate,
                "bin": this.dataService.order.bin
            };

            this.ordersService.updateOrder(this.dataService.order.po_nbr, orderObj).pipe(
                map( async order => {
                    console.log(order);

                    /*
                    this.notificationService.getRequestorEmail(this.dataService.order.requestor).pipe(
                        map( async email => {
                            console.log(email);
                            var mailObj = {
                                "to": email.email,
                                "subject": "Your purchase order has been approved",
                                "text": `Your purchase order - ${this.dataService.order.po_nbr} has been approved.`,
                                "html": `Your purchase order - ${this.dataService.order.po_nbr} has been approved.<br><br><a href="${environment.homepage}">Purchase Order Site</a>`
                            };                        
                            
                            this.notificationService.sendNotification(mailObj).pipe(
                                map( async notification => {
                                    console.log(notification);
                                })
                            ).subscribe();


                        })
                    ).subscribe();  
                    */



                }),
            ).subscribe();

            this.updatePOitems();
            await this.delay(500);
            this.router.navigate(['/dashboard'], { relativeTo: this.route });
        }
    })
  }

  async denyPO() {
    if (this.dataService.order.freight === null) {
        this.dataService.order.freight=0;
    }
    if (this.dataService.order.taxpercent === null) {
        this.dataService.order.taxpercent=0;
    }
    const confirmBox = new ConfirmBoxInitializer();
    confirmBox.setTitle('Confirm');
    confirmBox.setMessage('Are you sure you want to deny this purchase order?');
    confirmBox.setButtonLabels('YES', 'NO');

    // Choose layout color type
    confirmBox.setConfig({
        layoutType: DialogLayoutDisplay.SUCCESS, // SUCCESS | INFO | NONE | DANGER | WARNING
    });

    // Simply open the popup and listen which button is clicked
    confirmBox.openConfirmBox$().subscribe(async resp => {
        // IConfirmBoxPublicResponse
        console.log('Clicked button response: ', resp);

        if (resp.clickedButtonID === "yes") {

            this.calcTotals();

            var orderObj; 
        
            var m = moment().format("YYYY-MM-DD HH:mm:ss");

            orderObj = {
                "status": "denied",
                "deniedby": this.account.user.id,
                "denieddate": m,
                "total": this.dataService.order.total,
                "pototal": this.dataService.order.pototal,
                "freight": this.dataService.order.freight,
                "tax": this.dataService.order.tax,
                "taxpercent": this.dataService.order.taxpercent,
                "paid": this.dataService.order.paid,
                "ponotes": this.dataService.order.ponotes,
                "fob": this.dataService.order.fob,
                "terms": this.dataService.order.terms,
                "shipto": this.dataService.order.shipto,
                "shiptoaddress": this.dataService.order.shiptoaddress,
                "shipvia": this.dataService.order.shipvia,
                "shiptophone": this.dataService.order.shiptophone,
                "shiptofax": this.dataService.order.shiptofax,
                "emailed": this.dataService.order.emailed,
                "digitalinventorychecked": this.dataService.order.digitalinventorychecked,
                "physicalinventorychecked": this.dataService.order.physicalinventorychecked,
                "price": this.dataService.order.price,
                "whenneeded": this.dataService.order.whenneeded,
                "whenplaced": this.dataService.order.whenplaced,
                "expecteddate": this.dataService.order.expecteddate,
                "arrivaldate": this.dataService.order.arrivaldate,
                "bin": this.dataService.order.bin
            };
        
            this.ordersService.updateOrder(this.dataService.order.po_nbr, orderObj).pipe(
                map( async order => {
                    console.log(order);

                    this.notificationService.getRequestorEmail(this.dataService.order.requestor).pipe(
                        map( async email => {
                            console.log(email);
                            var mailObj = {
                                "to": email.email,
                                "subject": "Your purchase order has been denied",
                                "text": `Your purchase order - ${this.dataService.order.po_nbr} has been denied.`,
                                "html": `Your purchase order - ${this.dataService.order.po_nbr} has been denied.<br><br><a href="${environment.homepage}">Purchase Order Site</a>`
                            };                        
                            
                            this.notificationService.sendNotification(mailObj).pipe(
                                map( async notification => {
                                    console.log(notification);
                                })
                            ).subscribe();


                        })
                    ).subscribe();

                }),
            ).subscribe();
        
            this.updatePOitems();
            await this.delay(500);
            this.dataService.order = null;
            this.router.navigate(['/dashboard'], { relativeTo: this.route });    
        }
    })
  
  
    }

    async returnPOToRequestor() {
        if (this.dataService.order.freight === null) {
            this.dataService.order.freight=0;
        }
        if (this.dataService.order.taxpercent === null) {
            this.dataService.order.taxpercent=0;
        }
        const confirmBox = new ConfirmBoxInitializer();
        confirmBox.setTitle('Confirm');
        confirmBox.setMessage('Are you sure you want to return this purchase order to the requestor?');
        confirmBox.setButtonLabels('YES', 'NO');
    
        // Choose layout color type
        confirmBox.setConfig({
            layoutType: DialogLayoutDisplay.SUCCESS, // SUCCESS | INFO | NONE | DANGER | WARNING
        });
    
        // Simply open the popup and listen which button is clicked
        confirmBox.openConfirmBox$().subscribe(async resp => {
            // IConfirmBoxPublicResponse
            console.log('Clicked button response: ', resp);
    
            if (resp.clickedButtonID === "yes") {
    
                this.calcTotals();

                var orderObj; 
            
                var m = moment().format("YYYY-MM-DD HH:mm:ss");

                orderObj = {
                    "status": "draft",
                    "deniedby": this.account.user.id,
                    "denieddate": m,
                    "approver1": null,
                    "approver1date": '0000-00-00 00:00:00',
                    "approver2": null,
                    "approver2date": '0000-00-00 00:00:00',
                    "total": this.dataService.order.total,
                    "pototal": this.dataService.order.pototal,
                    "freight": this.dataService.order.freight,
                    "tax": this.dataService.order.tax,
                    "taxpercent": this.dataService.order.taxpercent,
                    "paid": this.dataService.order.paid,
                    "ponotes": this.dataService.order.ponotes,
                    "fob": this.dataService.order.fob,
                    "terms": this.dataService.order.terms,
                    "shipto": this.dataService.order.shipto,
                    "shiptoaddress": this.dataService.order.shiptoaddress,
                    "shipvia": this.dataService.order.shipvia,
                    "shiptophone": this.dataService.order.shiptophone,
                    "shiptofax": this.dataService.order.shiptofax,
                    "emailed": this.dataService.order.emailed,
                    "digitalinventorychecked": this.dataService.order.digitalinventorychecked,
                    "physicalinventorychecked": this.dataService.order.physicalinventorychecked,
                    "price": this.dataService.order.price,
                    "whenneeded": this.dataService.order.whenneeded,
                    "whenplaced": this.dataService.order.whenplaced,
                    "expecteddate": this.dataService.order.expecteddate,
                    "arrivaldate": this.dataService.order.arrivaldate,
                    "bin": this.dataService.order.bin
                };
            
                this.ordersService.updateOrder(this.dataService.order.po_nbr, orderObj).pipe(
                    map( async order => {
                        console.log(order);

                        this.notificationService.getRequestorEmail(this.dataService.order.requestor).pipe(
                            map( async email => {
                                console.log(email);
                                var mailObj = {
                                    "to": email.email,
                                    "subject": "Your purchase order has been returned",
                                    "text": `Your purchase order - ${this.dataService.order.po_nbr} has been returned.`,
                                    "html": `Your purchase order - ${this.dataService.order.po_nbr} has been returned.<br><br><a href="${environment.homepage}">Purchase Order Site</a>`
                                };                        
                                
                                this.notificationService.sendNotification(mailObj).pipe(
                                    map( async notification => {
                                        console.log(notification);
                                    })
                                ).subscribe();
    
    
                            })
                        ).subscribe();
    
                    }),
                ).subscribe();
            
                this.updatePOitems();
                await this.delay(500);
                this.dataService.order = null;
                this.router.navigate(['/dashboard'], { relativeTo: this.route });    
            }
        })
      
      
        }

        async returnPOToPM() {
            if (this.dataService.order.freight === null) {
                this.dataService.order.freight=0;
            }
            if (this.dataService.order.taxpercent === null) {
                this.dataService.order.taxpercent=0;
            }
            const confirmBox = new ConfirmBoxInitializer();
            confirmBox.setTitle('Confirm');
            confirmBox.setMessage('Are you sure you want to return this purchase order to the purchasing manager(s)?');
            confirmBox.setButtonLabels('YES', 'NO');
        
            // Choose layout color type
            confirmBox.setConfig({
                layoutType: DialogLayoutDisplay.SUCCESS, // SUCCESS | INFO | NONE | DANGER | WARNING
            });
        
            // Simply open the popup and listen which button is clicked
            confirmBox.openConfirmBox$().subscribe(async resp => {
                // IConfirmBoxPublicResponse
                console.log('Clicked button response: ', resp);
        
                if (resp.clickedButtonID === "yes") {
        
                    this.calcTotals();

                    var orderObj; 
                
                    orderObj = {
                        "status": "new",
                        "approver1": null,
                        "approver1date": '0000-00-00 00:00:00',
                        "total": this.dataService.order.total,
                        "pototal": this.dataService.order.pototal,
                        "freight": this.dataService.order.freight,
                        "tax": this.dataService.order.tax,
                        "taxpercent": this.dataService.order.taxpercent,
                        "paid": this.dataService.order.paid,
                        "ponotes": this.dataService.order.ponotes,
                        "fob": this.dataService.order.fob,
                        "terms": this.dataService.order.terms,
                        "shipto": this.dataService.order.shipto,
                        "shiptoaddress": this.dataService.order.shiptoaddress,
                        "shipvia": this.dataService.order.shipvia,
                        "shiptophone": this.dataService.order.shiptophone,
                        "shiptofax": this.dataService.order.shiptofax,
                        "emailed": this.dataService.order.emailed,
                        "digitalinventorychecked": this.dataService.order.digitalinventorychecked,
                        "physicalinventorychecked": this.dataService.order.physicalinventorychecked,
                        "price": this.dataService.order.price,
                        "whenneeded": this.dataService.order.whenneeded,
                        "whenplaced": this.dataService.order.whenplaced,
                        "expecteddate": this.dataService.order.expecteddate,
                        "arrivaldate": this.dataService.order.arrivaldate,
                        "bin": this.dataService.order.bin
                    };
                
                    this.ordersService.updateOrder(this.dataService.order.po_nbr, orderObj).pipe(
                        map( async order => {
                            console.log(order);

                            this.notificationService.getRequestorEmail(this.dataService.order.approver1).pipe(
                                map( async email => {
                                    console.log(email);
                                    var mailObj = {
                                        "to": email.email,
                                        "subject": "A purchase order has been returned",
                                        "text": `A purchase order you approved has been returned - ${this.dataService.order.po_nbr}.`,
                                        "html": `A purchase order you approved has been returned - ${this.dataService.order.po_nbr}.<br><br><a href="${environment.homepage}">Purchase Order Site</a>`
                                    };                        
                                    
                                    this.notificationService.sendNotification(mailObj).pipe(
                                        map( async notification => {
                                            console.log(notification);
                                        })
                                    ).subscribe();
        
        
                                })
                            ).subscribe();
        

                        }),
                    ).subscribe();
                
                    this.updatePOitems();
                    await this.delay(500);
                    this.dataService.order = null;
                    this.router.navigate(['/dashboard'], { relativeTo: this.route });    
                }
            })
          
          
            }
    

  deletePO() {
    const confirmBox = new ConfirmBoxInitializer();
    confirmBox.setTitle('Confirm');
    confirmBox.setMessage('Are you sure you want to delete this purchase order?');
    confirmBox.setButtonLabels('YES', 'NO');

    // Choose layout color type
    confirmBox.setConfig({
        layoutType: DialogLayoutDisplay.DANGER, // SUCCESS | INFO | NONE | DANGER | WARNING
    });

    // Simply open the popup and listen which button is clicked
    confirmBox.openConfirmBox$().subscribe(resp => {
        // IConfirmBoxPublicResponse
        console.log('Clicked button response: ', resp);

        if (resp.clickedButtonID === "yes") {
            this.ordersService.deleteOrder(this.dataService.order.id).pipe(
                ).subscribe();
            
                this.dataService.order = null;
                this.router.navigate(['/dashboard'], { relativeTo: this.route });    
        }
    });

  }

  delay(ms: number)
  {
      return new Promise(resolve => setTimeout(resolve, ms));
  }


  updatePO() {
    console.log(this.dataService.order);
    this.updatePOitems();

    this.loadOrder(this.dataService.order.po_nbr);

    // for now assume we are only updating the items
/*
    debugger;
    var orderObj = {
        "status": "new"
        //"requestor": this.accountService.accountValue.user.id,
        //"approver1":this.dataService.approver1,
        //"approver2":this.dataService.approver2,
        //"items": this.dataService.orderitems
    };

    this.ordersService.updateOrder(this.dataService.order.po_nbr, orderObj).pipe(
        map( order => {
            console.log(order);
            this.dataService.order = order;
        }),
    ).subscribe();
*/
  }

  updatePOitems() {


    var oService = this.ordersService;

    var poNbr = this.dataService.order.id;

    if (this.dataService.order.items) {

        this.dataService.order.items.forEach(function (element) {
            if (element.source==='database') {
                console.log("No update: ");
                console.log(element);
            }
            if (element.source==='updated') {
                console.log("Updated item: ");
                console.log(element);
                oService.updateItem(element).pipe(
                    map( order => {
                        console.log(order);
                    }),
                ).subscribe();
            }        
            if (element.source==='deleted' && element.id!=0) {
                console.log("Deleted item: ");
                console.log(element);
                //alert("Deleting -" + element.id)
                oService.removeItem(element).pipe(
                    map( order => {
                        console.log(order);
                    }),
                ).subscribe();
            }                
            if (element.source==='new') {
                
                console.log("New item: ");
                console.log(element);

                oService.addItem(element, poNbr).pipe(
                    map( order => {
                        console.log(order);
                    }),
                ).subscribe();
            }                
        })
    }
  }

  markReceived(obj: MatCheckboxChange) {
    if (obj.checked) {
        this.setReceived(obj);
    } else {
        this.unsetReceived(obj);
    }


  }

  setReceived(obj: MatCheckboxChange) {

    const confirmBox = new ConfirmBoxInitializer();
    confirmBox.setTitle('Confirm');
    confirmBox.setMessage('Are you sure you want to mark this PO as received?');
    confirmBox.setButtonLabels('YES', 'NO');

    // Choose layout color type
    confirmBox.setConfig({
        layoutType: DialogLayoutDisplay.SUCCESS, // SUCCESS | INFO | NONE | DANGER | WARNING
    });


    // Simply open the popup and listen which button is clicked
    confirmBox.openConfirmBox$().subscribe(resp => {
        // IConfirmBoxPublicResponse
        if (resp.clickedButtonID === "yes") {

            debugger;
            var m = moment().format("YYYY-MM-DD HH:mm:ss");

            // convert using the TZDB identifier for US Central time
            //m.tz('America/Chicago');

            //var tDate:any = new Date();
            //tDate = tDate.toISOString().substr(0,10) + " " +  tDate.toISOString().substr(12,8)

            this.ordersService.updateOrder(this.dataService.order.po_nbr, 
                    {
                        received: true, 
                        status: 'completed',
                        datereceived: m
                    }
                ).pipe(
                ).subscribe();
            
                this.dataService.order = null;
                this.router.navigate(['/dashboard'], { relativeTo: this.route });    
        } else {
            obj.source.toggle();

        }
    });

  }

  unsetReceived(obj: MatCheckboxChange) {

    const confirmBox = new ConfirmBoxInitializer();
    confirmBox.setTitle('Confirm');
    confirmBox.setMessage('This PO is already set as received.  Are you sure you want to unmark this PO as received?');
    confirmBox.setButtonLabels('YES', 'NO');

    // Choose layout color type
    confirmBox.setConfig({
        layoutType: DialogLayoutDisplay.DANGER, // SUCCESS | INFO | NONE | DANGER | WARNING
    });

    // Simply open the popup and listen which button is clicked
    confirmBox.openConfirmBox$().subscribe(resp => {
        // IConfirmBoxPublicResponse
        if (resp.clickedButtonID === "yes") {
            this.ordersService.updateOrder(this.dataService.order.po_nbr, {received: false, status: 'approved', datereceived: null}).pipe(
                ).subscribe();
            
                this.dataService.order = null;
                this.router.navigate(['/dashboard'], { relativeTo: this.route });    
        } else {
            obj.source.toggle();

        }        
    });

  }  

  calcTotals() {
    debugger;

    if (this.dataService.order.freight === null) {
        this.dataService.order.freight=0;
    }
    if (this.dataService.order.taxtercent === null) {
        this.dataService.order.taxpercent=0;
    }


    this.dataService.order.subtotal = 0

    if (this.dataService.order.items) {

    this.dataService.order.items.forEach(element => {

        if (element.source != 'deleted') {
            this.dataService.order.subtotal = this.dataService.order.subtotal + element.total_price;
        }
    });
    }
    this.dataService.order.subtotal = this.dataService.order.subtotal + this.dataService.order.freight
    this.dataService.order.tax = (this.dataService.order.subtotal*this.dataService.order.taxpercent);
    this.dataService.order.total = this.dataService.order.subtotal + this.dataService.order.tax;
    this.dataService.order.pototal = this.dataService.order.total + this.dataService.order.paid;

    // convert any dates
    if (this.dataService.order.whenneeded) {
        if (typeof this.dataService.order.whenneeded === "object") {
            this.dataService.order.whenneeded = this.dataService.order.whenneeded.toISOString().substr(0,10);
        } else {
            this.dataService.order.whenneeded = this.dataService.order.whenneeded.substr(0,10);
        }
    }

    if (this.dataService.order.whenplaced) {
        if (typeof this.dataService.order.whenplaced == "object") {
            this.dataService.order.whenplaced = this.dataService.order.whenplaced.toISOString().substr(0,10);
        } else {
            this.dataService.order.whenplaced = this.dataService.order.whenplaced.substr(0,10);
        }
    }

    if (this.dataService.order.expecteddate) {
        if (typeof this.dataService.order.expecteddate == "object") {
            this.dataService.order.expecteddate = this.dataService.order.expecteddate.toISOString().substr(0,10);
        } else {
            this.dataService.order.expecteddate = this.dataService.order.expecteddate.substr(0,10);
        }
    }

    if (this.dataService.order.arrivaldate) {
        if (typeof this.dataService.order.arrivaldate == "object") {
            this.dataService.order.arrivaldate = this.dataService.order.arrivaldate.toISOString().substr(0,10);
        } else {
            this.dataService.order.arrivaldate = this.dataService.order.arrivaldate.substr(0,10);
        }
    }

  }

  arrived(item) {
    var dt = new Date()
    item.arrived = dt.toISOString().substr(0,10);
    
    var oService = this.ordersService;

   

    oService.updateArrived(item.id).pipe(

    ).subscribe();

  }

  unarrived(item) {
    item.arrived = null;


    var oService = this.ordersService;



    
    oService.updateNotArrived(item.id).pipe(

    ).subscribe();

  }


}
