export class Order {
    page_count: number;
    id: number;
    tmp_po_nbr: number;
    po_nbr: number;
    po_date: Date;
    vendor: number;
    approver1: number;
    approver1date: Date;
    approver2: number;
    approver2date: Date;
    status: string;
  
}