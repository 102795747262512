import { NgModule } from '@angular/core';
import { Routes, RouterModule, NoPreloading } from '@angular/router';
import { AuthGuard } from './_helpers';
import { Role } from './_models';
import { DashboardComponent } from './orders/dashboard';
import { CompletedOrdersComponent } from './orders/orders/completedorders.component';
import { AllOrdersComponent } from './orders/orders/allorders.component';
import { OrderComponent } from './orders/order';


import { OverviewComponent } from '@app/admin/overview.component';
import { ListComponent } from '@app/admin/accounts/list.component';
import { AddEditComponent } from '@app/admin/accounts/add-edit.component';

import { LayoutComponent } from '@app/profile/layout.component';
import { DetailsComponent } from '@app/profile/details.component';
import { UpdateComponent } from '@app/profile/update.component'; 

    
const accountModule = () => import('./account/account.module').then(x => x.AccountModule);
const adminModule = () => import('./admin/admin.module').then(x => x.AdminModule);
const profileModule = () => import('./profile/profile.module').then(x => x.ProfileModule);

const routes: Routes = [
    { path: 'orders/dashboard', component: DashboardComponent, canActivate: [AuthGuard] },
    { path: 'orders/completedorders', component: CompletedOrdersComponent, canActivate: [AuthGuard] },
    { path: 'orders/allorders', component: AllOrdersComponent, canActivate: [AuthGuard] },
    { path: 'orders/order', component: OrderComponent, canActivate: [AuthGuard] },
    { path: 'admin/overview', component: OverviewComponent, canActivate: [AuthGuard], data: { roles: ['approver'] }  },
    { path: 'admin/overview/accounts', component:ListComponent, canActivate: [AuthGuard], data: { roles: ['approver'] }  },
    { path: 'admin/overview/accounts/edit/:id', component: AddEditComponent },
    { path: 'admin/overview/accounts/add', component: AddEditComponent },
    { path: 'profile', component: LayoutComponent },
    { path: 'profile/details', component: DetailsComponent },
    { path: 'profile/update', component: UpdateComponent },
    { path: 'account', loadChildren: accountModule },
    //{ path: 'profile', loadChildren: profileModule },
    //{ path: 'admin', loadChildren: adminModule, canActivate: [AuthGuard], data: { roles: [Role.Admin] } },

    // otherwise redirect to home
    { path: '',   redirectTo: 'orders/dashboard', pathMatch: 'full' },
    { path: '**', redirectTo: 'account/login', pathMatch: 'full' }
];

@NgModule({
    //imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
    imports: [RouterModule.forRoot(routes, { enableTracing: false /*, relativeLinkResolution: 'legacy'*/ })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
